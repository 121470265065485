import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import { CheckIcon, ArrowLeftCircleIcon, CheckCircleIcon, DocumentIcon, BuildingOfficeIcon, TableCellsIcon, ListBulletIcon, PaperAirplaneIcon, PencilSquareIcon, ClockIcon, EyeIcon, XMarkIcon, PencilIcon } from "@heroicons/react/24/outline";
import { Modal, Table, TextInput, Button, Textarea, Dropdown } from 'flowbite-react';
import Autocomplete from '../components/Autocomplete';
import { hasMultipleCompanies, isAdmin } from '../utils/User';
import Cookies from 'js-cookie';
import moment from 'moment';
import { DatePicker, Progress, Segmented, message, Select, Table as ATable, Tag, AutoComplete } from 'antd';
import dayjs from 'dayjs';
import { Confirmation } from '../components/Confirmation';
import { userRequest } from '../makeRequest';

const defaultDeclarations = {
  salary: {
    base: { amount: '', hourlyrate: '' },
    primes: { amount: '', hourlyrate: '' },
    hs: { amount: '', hourlyrate: '' },
    other: { amount: '', hourlyrate: '' },
  },
  absences: {
    maladie: { from: '', to: '', nb: '' },
    rtt: { from: '', to: '', nb: '' },
    cp: { from: '', to: '', nb: '' },
    other: { from: '', to: '', nb: '' },
  },
  advantages: {
    ticketrepas: { nb: '' },
    titredetransport: { nb: '' },
    deplacement: { nb: '' },
    other: { nb: '' },
  },
  deductions: {
    acompte: { amount: '' },
    saisiearret: { amount: '' },
    other: { amount: '' },
  },
  observations: ''
}
const Declarations = () => {
  const [openModal, setOpenModal] = useState(false);
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [employees, setEmployees] = useState(null);
  const [month, setMonth] = useState(dayjs().format('DD-MM-YYYY'));
  const [declarations, setDeclarations] = useState(defaultDeclarations);
  const [employeesOld, setEmployeesOld] = useState(null);
  const [step, setStep] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [hasDeclaration, setHasDeclaration] = useState(false);
  const [companies, setCompanies] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [display, setDisplay] = useState(localStorage.getItem('display') || 'table');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filter, setFilter] = useState('all');
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {

    if (isAdmin() || hasMultipleCompanies()) {
      fetchCompanies();
    } else {
      fetchDeclarations();
      setSelectedCompany({ id: null, value: null });
    }

  }, []);

  useEffect(() => {
    // setEmployees()
    let xx = employeesOld?.filter(item => {
      if (filter == 'ALL') {
        return true;
      } else if (filter == 'NOT_DECLARED') {
        return item?.declarations?.status === null || !item?.declarations?.status
      } else if (filter == 'CONFIRMED') {
        if (isAdmin()) {
          return item?.declarations?.status === 'CONFIRMED'
        } else {
          return item?.declarations?.status === 'CONFIRMED' || item?.declarations?.status === 'TREATED'
        }
      } else {
        return item?.declarations?.status === filter
      }
    })
    // console.log(xx)
    setEmployees(xx)
  }, [filter])

  const fetchDeclarations = async (company = null, search = null) => {
    setEmployeeInfo({});
    setEmployees(null)
    setFilter('ALL')
    try {
      const response = await fetch(`http://127.0.0.1:8000/api/declaration?date=${moment(month, 'DD-MM-YYYY').format('MM-YYYY')}${company ? '&company=' + company : ''}${search ? '&employee.lastname=' + search : ''}`, {
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`
        }
      });
      const data = await response.json();
      const declarationsWithKeys = data['declarations'].map((declaration, index) => ({
        ...declaration,
        key: declaration.id
      }));
      setEmployees(declarationsWithKeys);
      setEmployeesOld(declarationsWithKeys);
      setSelectedCompany({ id: data['company'].id, value: data['company'].name });
    }
    catch (error) {
      console.error('Error:', error);
    }
  }

  const fetchCompanies = async () => {
    setCompanies(null);
    try {
      const response = await fetch(`http://127.0.0.1:8000/api/companies_all`, {
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`
        }
      });
      const data = await response.json();

      let companyList = [];
      data.forEach((company) => {
        companyList.push({ id: company.id, value: company.name });
      })
      setCompanies(companyList);
    }
    catch (error) {
      console.error('Error:', error);
    }
  }

  const manageDeclaration = async (employee, method = 'POST', confirm = false) => {
    setIsEdit(false);
    try {
      const response = await fetch(`http://127.0.0.1:8000/api/declaration`, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('token')}`
        },
        body: JSON.stringify({ ...declarations, employee_id: employee, date: month, confirm: confirm })
      })
      const data = await response.json();
      if (response.ok) {
        message.success('Déclaration ajoutée avec succès');
        setOpenModal(false);
        fetchDeclarations(selectedCompany.id);
      }
    }
    catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    if (isRendered == false) {
      setIsRendered(true);
    } else {
      if ((isAdmin() || hasMultipleCompanies()) && selectedCompany) {
        fetchDeclarations(selectedCompany.id);
      } else {
        fetchDeclarations();
      }

    }
  }, [month]);

  useEffect(() => {
    setStep(0);
  }, [openModal]);

  const markAsTreated = async (employee) => {
    try {
      const response = await fetch(`http://127.0.0.1:8000/api/mark_treated`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('token')}`
        },
        body: JSON.stringify({ employees_id: employee, month: dayjs(month, 'DD-MM-YYYY').format('MM-YYYY') })
      })
      const data = await response.json();
      if (response.ok) {
        message.success('Déclaration traitée avec succès');
        setOpenModal(false);
        fetchDeclarations(selectedCompany.id);
      }
    }
    catch (error) {
      message.error('Erreur lors du traitement de la déclaration');
      console.error('Error:', error);
    }
  }

  const disabledFutureMonths = (current) => {
    return current && current.isAfter(dayjs(), 'month');
  };

  const getSameAsLastMonth = async (employees) => {
    try {
      const response = await fetch(`http://127.0.0.1:8000/api/declarations/same_as_last_month`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('token')}`
        },
        body: JSON.stringify({ employees_id: employees, month: dayjs(month, 'DD-MM-YYYY').format('MM-YYYY'), company_id: selectedCompany.id })
      })
      const data = await response.json();
      if (response.ok) {
        message.success('Déclarations reprises avec succès');
        setOpenModal(false);
        fetchDeclarations(selectedCompany.id);
      }
    }
    catch (error) {
      message.error('Erreur lors du traitement de la déclaration');
      console.error('Error:', error);
    }
  }

  const confirmDeclaration = async (employees) => {
    try {
      const response = await fetch(`http://127.0.0.1:8000/api/declarations/confirm`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('token')}`
        },
        body: JSON.stringify({ employees_id: employees, month: dayjs(month, 'DD-MM-YYYY').format('MM-YYYY'), company_id: selectedCompany.id })
      })
      const data = await response.json();
      if (response.ok) {
        message.success('Déclaration traitée avec succès');
        setOpenModal(false);
        fetchDeclarations(selectedCompany.id);
      }
    }
    catch (error) {
      message.error('Erreur lors du traitement de la déclaration');
      console.error('Error:', error);
    }
  }


  const allowEditDeclaration = async (employee) => {

    Confirmation({
      title: 'Confirmation', confirmButtonText: 'Autoriser', content: <div>
        <p>Voulez-vous vraiment autoriser la modification de la déclaration pour cet employé ?</p>
      </div>, onConfirm: async () => {
        const res = await userRequest.post("declarations/allow_edit",
          { employee: employee, month: dayjs(month, 'DD-MM-YYYY').format('MM-YYYY') }
        );
        if (res.status == 201) {
          message.success('Déclaration débloquée avec succès');
          fetchDeclarations(selectedCompany.id);
        } else {
          message.error('Erreur lors du déblocage de la déclaration');
        }
      }
    })
  }

  useEffect(() => {
    console.log(declarations)
  }, [declarations])

  return (
    <div className="relative min-h-screen md:flex" data-dev-hint="container">
      <Navbar />
      <Sidebar />
      <main id="content" className="flex-1 p-6 lg:px-8">
        <div className="flex-wrap items-center justify-between sm:flex">
          <div className="w-1/2">
            {
              selectedCompany && (isAdmin() || hasMultipleCompanies()) ?
                <div className='text-indigo-700 items-center flex ' role='button' onClick={() => setSelectedCompany(null)}>
                  <ArrowLeftCircleIcon className='h-7 w-7 inline-block me-2' />
                  Retour
                </div>
                :
                null
            }
            <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
              Les variables salariales
            </h3>
            <p className="text-gray-600 mt-2 text-sm">
              Ici vous pouvez consulter l'ensemble des statistiques
            </p>
          </div>
          <div className="w-1/2">
            <div className="flex items-center justify-end gap-2">
              {
                selectedCompany && employees &&
                <h1 className='text-2xl font-bold text-end items-center flex gap-1  '>
                  <BuildingOfficeIcon className='h-8 w-5 inline-block' />
                  <span>{selectedCompany?.value}</span>
                </h1>
              }
              {/* <div className="w-1/2">
                {
                  hasMultipleCompanies() ?
                    <AutoComplete
                      size='large'
                      className="w-full"
                      allowClear={true}
                      placeholder="Rechercher une entreprise"
                      options={companies}
                      filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onClear={() => {
                        fetchDashboardData();
                        setSelectedCompany(null);
                      }}
                      onSelect={(_, company) => {
                        setSelectedCompany(company.id);
                        fetchDashboardData(company.id);
                      }}
                    />
                    :
                    null
                }
              </div>
              <div className="w-1/2">
                <DatePicker
                  size='large'
                  className='w-full'
                  allowClear={false}
                  picker='month'
                  value={dayjs(month)}
                  onChange={(date) => {
                    setMonth(date.format("YYYY-MM"));
                  }}
                  format={"YYYY-MM"}
                />
              </div> */}
            </div>
          </div>
          {/* <Button onClick={showModal} gradientMonochrome="purple" className="mt-5 md:mt-0">Ajouter</Button> */}
        </div>
        <div className="max-w-7xl mx-auto">
          {
            selectedCompany ? <>
              <div className='flex  mt-10 items-end'>
                {/* <div className="flex items-center">
                  <span className="text-sm me-2">Affichage</span>
                  <Segmented
                    options={[
                      { label: <TableCellsIcon className="h-7 py-1 w-6" />, value: 'card' },
                      { label: <ListBulletIcon className="h-7 py-1 w-6" />, value: 'table' },
                    ]}
                    value={display}
                    onChange={(value) => {
                      setDisplay(value)
                      localStorage.setItem('display', value);
                    }}
                  />
                </div> */}
                <div className='flex items-center gap-2'>
                  <span className="text-sm me-2">Le mois</span>
                  <DatePicker
                    picker="month"
                    onChange={(e, date) => setMonth(dayjs(date, 'MM-YYYY').format('DD-MM-YYYY'))}
                    value={dayjs(month, 'DD-MM-YYYY')}
                    format={'MM-YYYY'}
                    allowClear={false}
                    disabledDate={disabledFutureMonths}
                    className='w-48 border-1 border-gray-300 rounded-md hover:border-indigo-700 focus:border-indigo-700 focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50 me'
                  />
                </div>
                <div className="flex items-center ms-2">
                  <span className="text-sm me-2">Filtrer par </span>
                  <select
                    size='large'
                    className="border border-gray-300 rounded-md px-2 py-1 text-sm"
                    value={filter}
                    onChange={(value) => {
                      setFilter(value.target.value)
                    }}
                  >
                    <option value='ALL'>Tous</option>
                    <option value='DECLARED'>Déclaré</option>
                    <option value='NOT_DECLARED'>Non déclaré</option>
                    <option value='CONFIRMED'>Envoyé</option>
                    {
                      isAdmin() ?
                        <option value='TREATED'>Traité</option>
                        :
                        null
                    }
                  </select>

                </div>
                {/* <div className="flex items-center ms-5">
                  <span className="text-sm me-2">Rechercher</span>
                  <input type="text" className="border border-gray-300 rounded-md px-2 py-1 placeholder-gray-500 placeholder-opacity-25" placeholder="Recherche par nom"
                    onChange={(e) => {
                      if (e.target.value === '') {
                        fetchDeclarations(selectedCompany.id);
                      } else if (e.target.value.length > 3) {
                        fetchDeclarations(selectedCompany.id, e.target.value);
                      }
                    }}
                  />
                </div> */}
                {
                  filter == 'ALL' &&
                  <div className='ml-auto w-1/3 p-4 bg-gray-100 rounded'>
                    <h3 className='text-sm font-bold my-2 '>Progression des déclarations envoyées</h3>
                    <Progress percent={employees?.length ? (employees.filter((employee) => employee.declarations?.status == 'CONFIRMED').length / employees.length) * 100 : 0} format={(e) => {
                      return employees ? `${employees.filter((employee) => employee.declarations?.status == 'CONFIRMED').length}/${employees.length}` : '0/0'
                    }} />
                  </div>
                }
              </div>

              <div className='flex mt-4'>
                {
                  selectedRowKeys.length > 0 ?
                    isAdmin() ?
                      <div className='ml-auto float-right'>
                        <div className='flex gap-2'>
                          <button className='bg-green-500 text-white px-2 py-1 rounded text-sm' onClick={() => {
                            Confirmation({
                              title: 'Confirmation', confirmButtonText: 'Marquer comme traité', content: <div>
                                <p>Voulez-vous vraiment marquer les déclarations comme traitées pour les employés sélectionnés ?</p>
                                <hr className='my-2' />
                                <small className='text-red-800'>Seulement les employés ayant des déclarations envoyées seront marqués comme traités</small>
                              </div>, onConfirm: () => markAsTreated(selectedRowKeys)
                            })
                          }}>
                            Marquer comme traité
                          </button>
                        </div>
                      </div>
                      :
                      <div className='ml-auto float-right'>
                        <div className='flex gap-2'>
                          <button className='bg-blue-500 text-white px-2 py-1 rounded text-sm' onClick={() => {
                            Confirmation({
                              title: 'Confirmation', confirmButtonText: 'Reprendre', content: <div>
                                <p>Voulez-vous vraiment reprendre les déclarations du mois précédent pour les employés sélectionnés ?</p>
                                <hr className='my-2' />
                                <small className='text-red-800'>Attention ! les déclarations actuelles pour ces employés seront écrasées</small>
                              </div>, onConfirm: () => getSameAsLastMonth(selectedRowKeys)
                            })
                          }}>Reprenez les déclarations du mois précédent</button>
                          <button className='bg-green-500 text-white px-2 py-1 rounded text-sm' onClick={() => {
                            Confirmation({
                              title: 'Confirmation', content: <div>
                                <p>Voulez-vous vraiment envoyer les déclarations pour les employés sélectionnés ?</p>
                                <hr className='my-2' />
                                <small className='text-blue-800'>Seulement les employés ayant des déclarations seront envoyés</small>
                              </div>, onConfirm: () => confirmDeclaration(selectedRowKeys)
                            })
                          }} >
                            Envoyer les déclarations
                          </button>
                        </div>
                      </div>
                    :
                    null
                }
              </div>

              {
                display === 'card' ?

                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-10">
                    {
                      employees?.map((employee, index) => (
                        <div key={index} className="bg-gray-100 shadow-sm rounded-md p-4">
                          <div className='flex justify-between mb-3'>
                            <h5 className='font-bold whitespace-nowrap overflow-hidden overflow-ellipsis'>{employee.firstname} {employee.lastname}</h5>
                            <span className='bg-indigo-700 text-white rounded px-2 inline-block uppercase'>{employee.contract}</span>
                          </div>
                          <small className='block'>Date de naissance : {moment(employee.birthday).format('DD/MM/YYYY')}</small>
                          <div className='flex align-bottom justify-between mt-4'>
                            {
                              isAdmin() ?
                                employee.declarations && !moment(employee.last_treatment).isSame(moment(), 'month') ?
                                  <button className='flex font-sm border bg-blue-500 text-white px-2 py-1 rounded' onClick={() => {
                                    setOpenModal(true)
                                    setEmployeeInfo(employee)
                                    setDeclarations(employee.declarations)
                                    setHasDeclaration(true)
                                  }}>
                                    <DocumentIcon className='h-5 w-5 me-2' />
                                    <span className='text-sm'> Commencer</span>
                                  </button>
                                  :
                                  employee.declarations ?
                                    <button className='flex font-sm border bg-green-500 text-white px-2 py-1 rounded' onClick={() => {
                                      setOpenModal(true)
                                      setEmployeeInfo(employee)
                                      setDeclarations(employee.declarations)
                                      setHasDeclaration(true)
                                    }}>
                                      <CheckCircleIcon className='h-5 w-5 me-2' />
                                      <span className='text-sm'>Traité</span>
                                    </button>
                                    :
                                    <button disabled className='flex font-sm border bg-gray-500 text-white px-2 py-1 rounded'>
                                      <DocumentIcon className='h-5 w-5 me-2' />
                                      <span className='text-sm'> Pas de déclaration</span>
                                    </button>
                                :
                                employee.declarations ?
                                  <button className='flex font-sm border bg-green-500 text-white px-2 py-1 rounded' onClick={() => {
                                    setOpenModal(true)
                                    setEmployeeInfo(employee)
                                    setDeclarations(employee.declarations)
                                    setHasDeclaration(true)
                                  }}>
                                    <CheckCircleIcon className='h-5 w-5 me-2' />
                                    <span className='text-sm'> Déja déclaré</span>
                                  </button>
                                  :
                                  <button className='flex font-sm border bg-gray-900 text-white px-2 py-1 rounded' onClick={() => {
                                    setOpenModal(true)
                                    setEmployeeInfo(employee)
                                    setDeclarations(defaultDeclarations)
                                    setHasDeclaration(false)
                                  }}>
                                    <DocumentIcon className='h-5 w-5 me-1' />
                                    <span className='text-sm'>Déclarer</span>
                                  </button>
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  :
                  <ATable
                    loading={!employees}
                    className=" mt-5"
                    dataSource={employees}
                    rowSelection={{
                      type: 'checkbox',
                      selectedRowKeys,
                      onChange: (selectedKeys) => {
                        setSelectedRowKeys(selectedKeys)
                        console.log(selectedKeys)
                      },
                    }}
                    pagination={{ pageSize: 50 }}
                    columns={[
                      {
                        title: 'Nom',
                        render: (record) => `${record.lastname} ${record.firstname}`,
                      },
                      {
                        title: 'Salaire',
                        render: (employee) => {
                          if (employee?.declarations?.salary) {
                            const salary = employee.declarations.salary;
                            const totalSalaryAmount = Object.values(salary).reduce((total, item) => total + (item.amount || 0), 0);
                            return <span className='text-xs'>{totalSalaryAmount} €</span>;
                          } else {
                            return <span className='text-xs italic text-gray-500'>Aucune déclaration</span>;
                          }
                        }
                      },
                      {
                        title: 'Absences',
                        render: (employee) => {
                          if (employee?.declarations?.absences) {
                            const absences = employee.declarations.absences;
                            let totalAbsenceDays = 0;

                            // Iterate over each absence type
                            Object.values(absences).forEach(absence => {
                              const fromDate = new Date(absence.from);
                              const toDate = new Date(absence.to);
                              const daysDifference = Math.ceil((toDate - fromDate) / (1000 * 60 * 60 * 24));
                              totalAbsenceDays += daysDifference;
                            });

                            return <span className='text-xs'>{totalAbsenceDays} jours</span>;
                          } else {
                            return <span className='text-xs italic text-gray-500'>Aucune absence</span>;
                          }
                        }
                      },
                      {
                        title: 'Avantages',
                        render: (employee) => {
                          if (employee?.declarations?.advantages) {
                            const advantages = employee.declarations.advantages;
                            const totalAdvantageAmount = Object.values(advantages).reduce((total, item) => total + (item.nb || 0), 0);
                            return <span className='text-xs'>{totalAdvantageAmount} €</span>;
                          } else {
                            return <span className='text-xs italic text-gray-500'>Aucun avantage</span>;
                          }
                        }
                      },
                      {
                        title: 'Déductions',
                        render: (employee) => {
                          if (employee?.declarations?.deductions) {
                            const deductions = employee.declarations.deductions;
                            const totalDeductionsAmount = Object.values(deductions).reduce((total, item) => total + (item.amount || 0), 0);
                            return <span className='text-xs'>{totalDeductionsAmount} €</span>;
                          } else {
                            return <span className='text-xs italic text-gray-500'>Aucun déduction</span>;
                          }
                        }
                      },
                      (
                        isAdmin() ?
                          {
                            title: 'Statut',
                            render: (employee) => (
                              employee.declarations ? (
                                employee.declarations.status == 'CONFIRMED' ?
                                  <Tag color='blue' className='text-xs italic'>Envoyé</Tag>
                                  :
                                  employee.declarations.status == 'DECLARED' ?
                                    <Tag color='orange' className='text-xs italic'>En cours</Tag>
                                    :
                                    employee.declarations.status == 'TREATED' ?
                                      <Tag color='green' className='text-xs italic'>Traité</Tag>
                                      :
                                      <Tag className='text-xs italic'>Pas de déclaration</Tag>
                              ) : (
                                <Tag className='text-xs italic'>Pas de déclaration</Tag>
                                // <span className='text-xs'>{employee.declarations ? employee.declarations.status : 'Non déclaré'}</span>
                              )
                            )
                          } : {}
                      )
                      , {
                        title: 'Actions',
                        render: (employee) => (
                          <div>
                            {
                              isAdmin() ?
                                employee.declarations && employee?.declarations?.status != 'TREATED' ?
                                  <div className=''>
                                    <button className='flex font-sm border bg-blue-500 text-white px-2 py-1 rounded' onClick={() => {
                                      setOpenModal(true)
                                      setEmployeeInfo(employee)
                                      setDeclarations(employee.declarations)
                                      setHasDeclaration(true)
                                    }}>
                                      <DocumentIcon className='h-5 w-5 me-2' />
                                      <span className='text-sm'> Voir la déclaration</span>
                                    </button>
                                    {
                                      employee.declarations.status == 'CONFIRMED' &&
                                      <button className='flex font-xs hover:bg-green-500 hover:text-white text-green-500 px-2 py-1 rounded ' onClick={() => {
                                        allowEditDeclaration(employee.id)
                                      }}>
                                        <PencilIcon className='h-4 w-4 me-1' />
                                        <span className='text-xs'>Autoriser la modification</span>
                                      </button>
                                    }
                                  </div>
                                  :
                                  employee.declarations ?
                                    <button className='flex font-sm border bg-green-500 text-white px-2 py-1 rounded' onClick={() => {
                                      setOpenModal(true)
                                      setEmployeeInfo(employee)
                                      setDeclarations(employee.declarations)
                                      setHasDeclaration(true)
                                    }}>
                                      <CheckCircleIcon className='h-5 w-5 me-2' />
                                      <span className='text-sm'>Voir la déclaration</span>
                                    </button>
                                    :
                                    <button disabled className='flex font-sm border bg-gray-500 text-white px-2 py-1 rounded'>
                                      <DocumentIcon className='h-5 w-5 me-2' />
                                      <span className='text-sm'> Pas de déclaration</span>
                                    </button>
                                :
                                employee.declarations ?
                                  employee?.declarations?.status == 'CONFIRMED' || employee?.declarations?.status == 'TREATED' ?
                                    <span className='flex italic text-xs text-green-500 gap-1 items-center'>
                                      <CheckCircleIcon className='h-5 w-5 ' />
                                      <span>Déclaration envoyée </span>
                                    </span>
                                    :
                                    <div className='flex'>
                                      <button title='Modifier la déclaration' className='flex font-sm border bg-blue-500 text-white px-2 py-1 rounded' onClick={() => {
                                        setOpenModal(true)
                                        setEmployeeInfo(employee)
                                        setDeclarations(employee.declarations)
                                        // setHasDeclaration(true)
                                        setIsEdit(true)
                                      }}>
                                        <PencilSquareIcon className='h-5 w-5' />
                                      </button>

                                      <button title='Modifier la déclaration' className='flex font-sm border bg-green-500 text-white px-2 py-1 rounded' onClick={() => {
                                        Confirmation({
                                          title: 'Confirmation', size: 'lg', confirmButtonText: 'Envoyer', content: <>
                                            <div>
                                              <p>Voulez-vous vraiment envoyer la déclaration ?</p>
                                              <p>Voici un récapitulatif de la déclaration :</p>
                                              <div className='text-left my-2'>
                                                <Recap declaration={employee.declarations} />
                                              </div>
                                              <small>Attention ! Une fois envoyée, la déclaration ne pourra plus être modifiée</small>
                                            </div>
                                          </>, onConfirm: () => confirmDeclaration([employee.id])
                                        })
                                      }}>
                                        <PaperAirplaneIcon className='h-5 w-5 me-2' />
                                        <span className='text-sm'>Envoyer</span>
                                      </button>
                                    </div>

                                  :
                                  <div className='flex'>
                                    <button title='Reprenez la déclaration du mois précedent' className='flex font-sm border bg-blue-500 text-white px-2 py-1 rounded'
                                      onClick={() => {
                                        Confirmation({
                                          title: 'Confirmation', confirmButtonText: 'Reprendre', content: 'Voulez-vous vraiment reprendre la déclaration du mois précédent ?', onConfirm: () => getSameAsLastMonth([employee.id])
                                        })
                                      }}>
                                      <ClockIcon className='h-5 w-5' />
                                    </button>
                                    <button className='flex font-sm border bg-gray-900 text-white px-2 py-1 rounded' onClick={() => {
                                      setOpenModal(true)
                                      setEmployeeInfo(employee)
                                      setDeclarations(defaultDeclarations)
                                      setHasDeclaration(false)
                                    }}>
                                      <DocumentIcon className='h-5 w-5 me-1' />
                                      <span className='text-sm'>Déclarer</span>
                                    </button>
                                  </div>
                            }

                          </div>

                        ),
                      },
                    ]}

                  />
              }
            </>
              :
              <div className="flex justify-center items-center mt-20">

                <div className="bg-gray-50 shadow-xs p-6 rounded-lg text-center">
                  <svg height={100} className='mx-auto mb-3' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M5.87868 2.87868C5 3.75736 5 5.17157 5 8V16C5 18.8284 5 20.2426 5.87868 21.1213C6.75736 22 8.17157 22 11 22H13C15.8284 22 17.2426 22 18.1213 21.1213C19 20.2426 19 18.8284 19 16V8C19 5.17157 19 3.75736 18.1213 2.87868C17.2426 2 15.8284 2 13 2H11C8.17157 2 6.75736 2 5.87868 2.87868ZM8.25 17C8.25 16.5858 8.58579 16.25 9 16.25H12C12.4142 16.25 12.75 16.5858 12.75 17C12.75 17.4142 12.4142 17.75 12 17.75H9C8.58579 17.75 8.25 17.4142 8.25 17ZM9 12.25C8.58579 12.25 8.25 12.5858 8.25 13C8.25 13.4142 8.58579 13.75 9 13.75H15C15.4142 13.75 15.75 13.4142 15.75 13C15.75 12.5858 15.4142 12.25 15 12.25H9ZM8.25 9C8.25 8.58579 8.58579 8.25 9 8.25H15C15.4142 8.25 15.75 8.58579 15.75 9C15.75 9.41421 15.4142 9.75 15 9.75H9C8.58579 9.75 8.25 9.41421 8.25 9Z" fill="#1C274C"></path> <path opacity="0.5" d="M5.23525 4.05811C5 4.94139 5 6.17689 5 7.99985V15.9999C5 17.8229 5 19.0584 5.23527 19.9417L5 19.9238C4.02491 19.8279 3.36857 19.6111 2.87868 19.1212C2 18.2425 2 16.8283 2 13.9999V9.99991C2 7.17148 2 5.75726 2.87868 4.87859C3.36857 4.3887 4.02491 4.17194 5 4.07602L5.23525 4.05811Z" fill="#1C274C"></path> <path opacity="0.5" d="M18.7646 19.9417C18.9999 19.0584 18.9999 17.8229 18.9999 15.9999V7.99985C18.9999 6.17689 18.9999 4.94139 18.7647 4.05811L18.9999 4.07602C19.975 4.17194 20.6314 4.3887 21.1212 4.87859C21.9999 5.75726 21.9999 7.17148 21.9999 9.99991V13.9999C21.9999 16.8283 21.9999 18.2425 21.1212 19.1212C20.6314 19.6111 19.975 19.8279 18.9999 19.9238L18.7646 19.9417Z" fill="#1C274C"></path> </g></svg>
                  <p className='text-2xl'>Les variables salariales</p>
                  <small>
                    Pour voir les variables salariales, veuillez sélectionner une entreprise
                  </small>
                  <div className='text-center mt-3'>
                    <AutoComplete
                      size='large'
                      className='text-left mx-auto'
                      options={companies}
                      style={{ width: 300 }}
                      onSelect={(company, option) => {
                        setSelectedCompany(company);
                        fetchDeclarations(option.id);
                      }}
                      filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                      placeholder="Rechercher une entreprise"
                    />
                  </div>
                </div>
              </div>
          }
        </div>
      </main >
      <Modal show={openModal} size={'4xl'} onClose={() => setOpenModal(false)}>
        <Modal.Header>{employeeInfo.firstname} {employeeInfo.lastname}</Modal.Header>
        <Modal.Body>
          <ol className="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse bg-gray-100 py-2 px-3 rounded-xl">
            <li className={"flex items-center space-x-2.5 rtl:space-x-reverse " + (step >= 0 ? "text-indigo-700 " : "text-gray-500 ")}>
              <span className={"flex text-xs items-center justify-center w-5 h-5 border rounded-full shrink-0 " + (step >= 0 ? "border-indigo-700 " : "border-gray-500 ")}>
                {
                  step >= 1 ? <CheckIcon className='h-4 w-4' /> : 1
                }
              </span>
              <span>
                <h3 className="font-medium text-sm leading-tight">Salaire</h3>
              </span>
            </li>
            <li className={"flex items-center space-x-2.5 rtl:space-x-reverse " + (step >= 1 ? "text-indigo-700 " : "text-gray-500 ")}>
              <span className={"flex text-xs items-center justify-center w-5 h-5 border rounded-full shrink-0 " + (step >= 1 ? "border-indigo-700 " : "border-gray-500 ")}>
                {
                  step >= 2 ? <CheckIcon className='h-4 w-4' /> : 2
                }
              </span>
              <span>
                <h3 className="font-medium text-sm leading-tight">Absences</h3>
              </span>
            </li>
            <li className={"flex items-center space-x-2.5 rtl:space-x-reverse " + (step >= 2 ? "text-indigo-700 " : "text-gray-500 ")}>
              <span className={"flex text-xs items-center justify-center w-5 h-5 border rounded-full shrink-0 " + (step >= 2 ? "border-indigo-700 " : "border-gray-500 ")}>
                {
                  step >= 3 ? <CheckIcon className='h-4 w-4' /> : 3
                }
              </span>
              <span>
                <h3 className="font-medium text-sm leading-tight">Avantages</h3>
              </span>
            </li>
            <li className={"flex items-center space-x-2.5 rtl:space-x-reverse " + (step >= 3 ? "text-indigo-700 " : "text-gray-500 ")}>
              <span className={"flex text-xs items-center justify-center w-5 h-5 border rounded-full shrink-0 " + (step >= 3 ? "border-indigo-700 " : "border-gray-500 ")}>
                {
                  step >= 4 ? <CheckIcon className='h-4 w-4' /> : 4
                }
              </span>
              <span>
                <h3 className="font-medium text-sm leading-tight">Déductions	</h3>
              </span>
            </li>
            <li className={"flex items-center space-x-2.5 rtl:space-x-reverse " + (step >= 4 ? "text-indigo-700 " : "text-gray-500 ")}>
              <span className={"flex text-xs items-center justify-center w-5 h-5 border rounded-full shrink-0 " + (step >= 4 ? "border-indigo-700 " : "border-gray-500 ")}>
                {
                  step >= 5 ? <CheckIcon className='h-4 w-4' /> : 5
                }
              </span>
              <span>
                <h3 className="font-medium text-sm leading-tight">Observations	</h3>
              </span>
            </li>
          </ol>


          <div className="space-y-6 p-6">
            {
              step === 0 ? <div>
                <h3 className='text-lg font-bold text-center'>Salaire</h3>
                <div className="mt-3">
                  <Table>
                    <Table.Head>
                      <Table.HeadCell>Type</Table.HeadCell>
                      <Table.HeadCell>Montant</Table.HeadCell>
                      <Table.HeadCell>Horaire</Table.HeadCell>
                    </Table.Head>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell className='bg-gray-50 text-xs uppercase text-gray-700'>Base</Table.Cell>
                        <Table.Cell>
                          <TextInput disabled={hasDeclaration} className='w-[100px]' value={declarations?.salary?.base?.amount} onChange={(e) => setDeclarations({ ...declarations, salary: { ...declarations.salary, base: { ...declarations.salary.base, amount: e.target.value } } })} />
                        </Table.Cell>
                        <Table.Cell>
                          <TextInput disabled={hasDeclaration} className='w-[100px]' value={declarations?.salary?.base?.hourlyrate} onChange={(e) => setDeclarations({ ...declarations, salary: { ...declarations.salary, base: { ...declarations.salary.base, hourlyrate: e.target.value } } })} />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell className='bg-gray-50 text-xs uppercase text-gray-700'>Primes</Table.Cell>
                        <Table.Cell>
                          <TextInput disabled={hasDeclaration} className='w-[100px]' value={declarations?.salary?.primes?.amount} onChange={(e) => setDeclarations({ ...declarations, salary: { ...declarations.salary, primes: { ...declarations.salary.primes, amount: e.target.value } } })} />
                        </Table.Cell>
                        <Table.Cell>
                          <TextInput disabled={hasDeclaration} className='w-[100px]' value={declarations?.salary?.primes?.hourlyrate} onChange={(e) => setDeclarations({ ...declarations, salary: { ...declarations.salary, primes: { ...declarations.salary.primes, hourlyrate: e.target.value } } })} />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell className='bg-gray-50 text-xs uppercase text-gray-700'>HS ou HC</Table.Cell>
                        <Table.Cell>
                          <TextInput disabled={hasDeclaration} className='w-[100px]' value={declarations?.salary?.hs?.amount} onChange={(e) => setDeclarations({ ...declarations, salary: { ...declarations.salary, hs: { ...declarations.salary.hs, amount: e.target.value } } })} />
                        </Table.Cell>
                        <Table.Cell>
                          <TextInput disabled={hasDeclaration} className='w-[100px]' value={declarations?.salary?.hs?.hourlyrate} onChange={(e) => setDeclarations({ ...declarations, salary: { ...declarations.salary, hs: { ...declarations.salary.hs, hourlyrate: e.target.value } } })} />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell className='bg-gray-50 text-xs uppercase text-gray-700'>Autre</Table.Cell>
                        <Table.Cell>
                          <TextInput disabled={hasDeclaration} className='w-[100px]' value={declarations?.salary?.other?.amount} onChange={(e) => setDeclarations({ ...declarations, salary: { ...declarations.salary, other: { ...declarations.salary.other, amount: e.target.value } } })} />
                        </Table.Cell>
                        <Table.Cell>
                          <TextInput disabled={hasDeclaration} className='w-[100px]' value={declarations?.salary?.other?.hourlyrate} onChange={(e) => setDeclarations({ ...declarations, salary: { ...declarations.salary, other: { ...declarations.salary.other, hourlyrate: e.target.value } } })} />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div> :
                step === 1 ? <div>
                  <h3 className='text-lg font-bold text-center'>Absences</h3>
                  <div className="mt-3">
                    <Table>
                      <Table.Head>
                        <Table.HeadCell>Type</Table.HeadCell>
                        <Table.HeadCell>Du</Table.HeadCell>
                        <Table.HeadCell>Au</Table.HeadCell>
                        <Table.HeadCell>Nbre h/jour</Table.HeadCell>
                      </Table.Head>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell className='bg-gray-50 text-xs uppercase text-gray-700'>Maladie</Table.Cell>
                          <Table.Cell>
                            <DatePicker
                              picker="date"
                              allowClear={false}
                              disabled={hasDeclaration}
                              className='w-48 border-1 border-gray-300 py-2 rounded-md hover:border-indigo-700 focus:border-indigo-700 focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50 me'
                              value={declarations?.absences?.maladie?.from ? dayjs(declarations?.absences?.maladie?.from, 'YYYY-MM-DD') : null}
                              onChange={(e) => setDeclarations({ ...declarations, absences: { ...declarations.absences, maladie: { ...declarations.absences?.maladie, from: e } } })}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <DatePicker
                              picker="date"
                              allowClear={false}
                              disabled={hasDeclaration}
                              className='w-48 border-1 border-gray-300 py-2 rounded-md hover:border-indigo-700 focus:border-indigo-700 focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50 me'
                              value={declarations?.absences?.maladie?.to ? dayjs(declarations?.absences?.maladie?.to, 'YYYY-MM-DD') : null}
                              onChange={(e) => setDeclarations({ ...declarations, absences: { ...declarations.absences, maladie: { ...declarations.absences?.maladie, to: e } } })}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <TextInput className='w-[100px]' value={declarations?.absences?.maladie?.nb} onChange={(e) => setDeclarations({ ...declarations, absences: { ...declarations.absences, maladie: { ...declarations.absences?.maladie, nb: e.target.value } } })} />
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell className='bg-gray-50 text-xs uppercase text-gray-700'>RTT</Table.Cell>
                          <Table.Cell>
                            <DatePicker
                              picker="date"
                              allowClear={false}
                              disabled={hasDeclaration}
                              className='w-48 border-1 border-gray-300 py-2 rounded-md hover:border-indigo-700 focus:border-indigo-700 focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50 me'
                              value={declarations?.absences?.rtt?.from ? dayjs(declarations?.absences?.rtt?.from, 'YYYY-MM-DD') : null}
                              onChange={(e) => setDeclarations({ ...declarations, absences: { ...declarations.absences, rtt: { ...declarations.absences?.rtt, from: e } } })}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <DatePicker
                              picker="date"
                              allowClear={false}
                              disabled={hasDeclaration}
                              className='w-48 border-1 border-gray-300 py-2 rounded-md hover:border-indigo-700 focus:border-indigo-700 focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50 me'
                              value={declarations?.absences?.rtt?.to ? dayjs(declarations?.absences?.rtt?.to, 'YYYY-MM-DD') : null}
                              onChange={(e) => setDeclarations({ ...declarations, absences: { ...declarations.absences, rtt: { ...declarations.absences?.rtt, to: e } } })}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <TextInput className='w-[100px]' value={declarations?.absences?.rtt?.nb} onChange={(e) => setDeclarations({ ...declarations, absences: { ...declarations.absences, rtt: { ...declarations.absences?.rtt, nb: e.target.value } } })} />
                          </Table.Cell>

                        </Table.Row>
                        <Table.Row>
                          <Table.Cell className='bg-gray-50 text-xs uppercase text-gray-700'>CP</Table.Cell>
                          <Table.Cell>
                            <DatePicker
                              picker="date"
                              allowClear={false}
                              disabled={hasDeclaration}
                              className='w-48 border-1 border-gray-300 py-2 rounded-md hover:border-indigo-700 focus:border-indigo-700 focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50 me'
                              value={declarations?.absences?.cp?.from ? dayjs(declarations?.absences?.cp?.from, 'YYYY-MM-DD') : null}
                              onChange={(e) => setDeclarations({ ...declarations, absences: { ...declarations.absences, cp: { ...declarations.absences?.cp, from: e } } })}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <DatePicker
                              picker="date"
                              allowClear={false}
                              disabled={hasDeclaration}
                              className='w-48 border-1 border-gray-300 py-2 rounded-md hover:border-indigo-700 focus:border-indigo-700 focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50 me'
                              value={declarations?.absences?.cp?.to ? dayjs(declarations?.absences?.cp?.to, 'YYYY-MM-DD') : null}
                              onChange={(e) => setDeclarations({ ...declarations, absences: { ...declarations.absences, cp: { ...declarations.absences?.cp, to: e } } })}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <TextInput className='w-[100px]' value={declarations?.absences?.cp?.nb} onChange={(e) => setDeclarations({ ...declarations, absences: { ...declarations.absences, cp: { ...declarations.absences?.cp, nb: e.target.value } } })} />
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell className='bg-gray-50 text-xs uppercase text-gray-700'>Autre</Table.Cell>
                          <Table.Cell>
                            <DatePicker
                              picker="date"
                              allowClear={false}
                              disabled={hasDeclaration}
                              className='w-48 border-1 border-gray-300 py-2 rounded-md hover:border-indigo-700 focus:border-indigo-700 focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50 me'
                              value={declarations?.absences?.other?.from ? dayjs(declarations?.absences?.other?.from, 'YYYY-MM-DD') : null}
                              onChange={(e) => setDeclarations({ ...declarations, absences: { ...declarations.absences, other: { ...declarations.absences?.other, from: e } } })}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <DatePicker
                              picker="date"
                              allowClear={false}
                              disabled={hasDeclaration}
                              className='w-48 border-1 border-gray-300 py-2 rounded-md hover:border-indigo-700 focus:border-indigo-700 focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50 me'
                              value={declarations?.absences?.other?.to ? dayjs(declarations?.absences?.other?.to, 'YYYY-MM-DD') : null}
                              onChange={(e) => setDeclarations({ ...declarations, absences: { ...declarations.absences, other: { ...declarations.absences?.other, to: e } } })}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <TextInput className='w-[100px]' value={declarations?.absences?.other?.nb} onChange={(e) => setDeclarations({ ...declarations, absences: { ...declarations.absences, other: { ...declarations.absences?.other, nb: e.target.value } } })} />
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div> :
                  step === 2 ? <div>
                    <h3 className='text-lg font-bold text-center'>Avantages</h3>
                    <div className="mt-3">
                      <Table>
                        <Table.Head>
                          <Table.HeadCell>Type</Table.HeadCell>
                          <Table.HeadCell>Mont/Nbre</Table.HeadCell>
                        </Table.Head>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell className='bg-gray-50 text-xs uppercase text-gray-700'>Ticket repas</Table.Cell>
                            <Table.Cell>
                              <TextInput disabled={hasDeclaration} className='w-[100px]' value={declarations?.advantages?.ticketrepas?.nb} onChange={(e) => setDeclarations({ ...declarations, advantages: { ...declarations.advantages, ticketrepas: { ...declarations.advantages?.ticketrepas, nb: e.target.value } } })} />
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell className='bg-gray-50 text-xs uppercase text-gray-700'>Titre de transport</Table.Cell>
                            <Table.Cell>
                              <TextInput disabled={hasDeclaration} className='w-[100px]' value={declarations?.advantages?.titredetransport?.nb} onChange={(e) => setDeclarations({ ...declarations, advantages: { ...declarations.advantages, titredetransport: { ...declarations.advantages?.titredetransport, nb: e.target.value } } })} />
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell className='bg-gray-50 text-xs uppercase text-gray-700'>Déplacement</Table.Cell>
                            <Table.Cell>
                              <TextInput disabled={hasDeclaration} className='w-[100px]' value={declarations?.advantages?.deplacement?.nb} onChange={(e) => setDeclarations({ ...declarations, advantages: { ...declarations.advantages, deplacement: { ...declarations.advantages?.deplacement, nb: e.target.value } } })} />
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell className='bg-gray-50 text-xs uppercase text-gray-700'>Autre</Table.Cell>
                            <Table.Cell>
                              <TextInput disabled={hasDeclaration} className='w-[100px]' value={declarations?.advantages?.other?.nb} onChange={(e) => setDeclarations({ ...declarations, advantages: { ...declarations.advantages, other: { ...declarations.advantages?.other, nb: e.target.value } } })} />
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </div>
                  </div> :
                    step === 3 ? <div>
                      <h3 className='text-lg font-bold text-center'>Déductions	</h3>
                      <div className="mt-3">
                        <Table>
                          <Table.Head>
                            <Table.HeadCell>Type</Table.HeadCell>
                            <Table.HeadCell>Montant</Table.HeadCell>
                          </Table.Head>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell className='bg-gray-50'>Acompte</Table.Cell>
                              <Table.Cell>
                                <TextInput disabled={hasDeclaration} className='w-[100px]' value={declarations?.deductions?.acompte?.amount} onChange={(e) => setDeclarations({ ...declarations, deductions: { ...declarations.deductions, acompte: { amount: e.target.value } } })} />
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='bg-gray-50'>Saisie arrêt</Table.Cell>
                              <Table.Cell>
                                <TextInput disabled={hasDeclaration} className='w-[100px]' value={declarations?.deductions?.saisiearret?.amount} onChange={(e) => setDeclarations({ ...declarations, deductions: { ...declarations.deductions, saisiearret: { amount: e.target.value } } })} />
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='bg-gray-50'>Autre</Table.Cell>
                              <Table.Cell>
                                <TextInput disabled={hasDeclaration} className='w-[100px]' value={declarations?.deductions?.other?.amount} onChange={(e) => setDeclarations({ ...declarations, deductions: { ...declarations.deductions, other: { amount: e.target.value } } })} />
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </div>
                    </div> :
                      step === 4 ? <div>
                        <h3 className='text-lg font-bold text-center'>Observations</h3>
                        <div className="mt-3">
                          <Textarea disabled={hasDeclaration} placeholder='Observations' value={declarations?.observations} onChange={(e) => setDeclarations({ ...declarations, observations: e.target.value })} />
                        </div>
                      </div> :
                        step === 5 ? <div>
                          <h3 className='text-lg font-bold text-center'>Récapitulatif</h3>
                          <div className="mt-3">
                            <Recap declaration={declarations} />
                          </div>
                        </div> :
                          null
            }
          </div>
        </Modal.Body>
        <Modal.Footer className='flex justify-between'>
          {
            step > 0 ? <Button color="white" className='text-indigo-700 border border-indigo-700' onClick={() => setStep(step - 1)}>Précédent</Button>
              :
              <Button color="white" className='text-indigo-700 border border-indigo-700' onClick={() => setOpenModal(false)}>Fermer</Button>
          }
          {
            step < 4 ?
              <div className="flex gap-2">
                {
                  !isAdmin() &&
                  <Button color='white' className='border border-green-500 text-green-500' onClick={() => {
                    manageDeclaration(employeeInfo.id, isEdit ? 'PUT' : 'POST')
                  }}>
                    <CheckCircleIcon className='h-5 w-5 me-2' />
                    Enregistrer
                  </Button>
                }
                <Button color='white' className='bg-indigo-700 text-white' onClick={() => setStep(step + 1)}>Suivant</Button>
              </div>
              :
              isAdmin() ?
                <Button disabled={employeeInfo?.declarations?.status != 'CONFIRMED'} color='white' className='bg-green-500 text-white' onClick={() => {
                  markAsTreated([employeeInfo.id])
                }}>
                  <CheckCircleIcon className='h-5 w-5 me-2' />
                  Marquer comme traité
                </Button>
                :
                hasDeclaration ?
                  <Button disabled color='white' className='bg-green-500 text-white' onClick={() => {
                  }}>
                    <CheckCircleIcon className='h-5 w-5 me-2' />
                    Marquer comme traité
                  </Button>
                  :
                  <div className='flex gap-2'>
                    <Button color='white' className='border border-green-500 text-green-500' onClick={() => {
                      manageDeclaration(employeeInfo.id, isEdit ? 'PUT' : 'POST')
                    }}>
                      <CheckCircleIcon className='h-5 w-5 me-2' />
                      Enregistrer
                    </Button>
                    <Button color='white' className='bg-green-500 text-white' onClick={() => {
                      if (step === 5) {
                        manageDeclaration(employeeInfo.id, isEdit ? 'PUT' : 'POST', true)
                        // alert('add it')
                      } else {
                        setStep(step + 1)
                      }

                    }}>
                      <PaperAirplaneIcon className='h-5 w-5 me-2' />
                      Enregistrer et envoyer
                    </Button>
                  </div>
          }
        </Modal.Footer>
      </Modal>
    </div >
  );
}

const Recap = ({ declaration }) => {
  return (
    Object.entries(declaration).map(([section, data]) => (
      section !== 'employee_id' && section !== 'status' && Object.values(data).some(subValue => {
        if (typeof subValue === 'object') {
          return Object.values(subValue).some(val => val !== "");
        }
        return subValue !== "";
      }) && (
        section === 'observations' ? (
          <div key={section} className="bg-gray-100 p-2 rounded-xl rounded-md mt-4 font-[monospace] text-xs w-100 ">
            <h2 className="text-sm font-semibold mb-2 text-center">Observations</h2>
            <p>{data}</p>
          </div>
        )
          :
          <div key={section} className="bg-gray-100 p-2 rounded-xl rounded-md mt-4 font-[monospace] text-xs w-100 ">
            <h2 className="text-sm font-semibold mb-2 text-center">
              {section === 'salary' ? 'Salaire' :
                section === 'absences' ? 'Absences' :
                  section === 'advantages' ? 'Avantages' :
                    section === 'deductions' ? 'Déductions' : section
              }
            </h2>
            {Object.entries(data).map(([key, value]) => (
              Object.values(value).some(subValue => subValue !== "") && (
                <div key={key} className='flex grid grid-cols-4'>
                  <h3 className="font-semibold uppercase">{key}</h3>
                  {Object.entries(value).map(([subKey, subValue]) => (
                    subValue !== "" && (
                      <p key={subKey} className="ml-2">
                        {subKey === 'nb' && section === 'absences' ? 'Heures/jour' :
                          subKey === 'nb' && section === 'advantages' ? 'Montant' :
                            subKey === 'amount' ? 'Montant' :
                              subKey === 'from' ? 'Du' :
                                subKey === 'to' ? 'Au' :
                                  subKey === 'hourlyrate' ? 'Horaires' :
                                    subKey}: {typeof subValue === 'object' ? dayjs(subValue).format('DD/MM/YYYY') : subValue}
                      </p>
                    )
                  ))}
                </div>
              )
            ))}

          </div>
      )
    ))
  )
}



export default Declarations
