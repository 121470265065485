import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import "./assets/css/styles.css";
// import 'bootstrap/dist/css/bootstrap.min.css';

import Home from "./pages/Home";
import Login from "./pages/Login";
import Companies from "./pages/Companies";
import Employees from "./pages/Employees";
import Comptes from "./pages/Comptes";
import Declarations from "./pages/Declarations";
import { isAuthenticated } from "./utils/Auth";
import Absences from "./pages/Absences";
import Logs from "./pages/Logs";
import { userRequest } from "./makeRequest";
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";

function App() {

  const privateRoute = (to) => {
    return isAuthenticated() ? (
      to
    ) : (
      <Navigate to="/login" replace />
    );
  };

  userRequest.interceptors.request.use(async (config) => {
    let token = Cookies.get('token');
    if (token) {
        try {
            let decodedToken = token && jwtDecode(token);
            let currentDate = new Date();
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                Cookies.remove('token');
                window.location.reload();
            }
        } catch (error) {
            console.error("Erreur lors du décodage du token :", error);
        }
    }
    return config;
  }, (err) => {
      return Promise.reject(err);
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={isAuthenticated() ? <Navigate to="/" replace /> : <Login />}></Route>
        <Route path="/" element={privateRoute(<Home />)} />
        <Route path="/companies" element={privateRoute(<Companies />)} />
        <Route path="/employees" element={privateRoute(<Employees />)} />
        <Route path="/declarations" element={privateRoute(<Declarations />)} />
        <Route path="/accounts" element={privateRoute(<Comptes />)} />
        <Route path="/absences" element={privateRoute(<Absences />)} />
        <Route path="/logs" element={privateRoute(<Logs />)} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;