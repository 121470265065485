import React, { useEffect, useState, useRef } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import {
  Tabs,
  Modal,
  Button,
  Alert,
} from "flowbite-react";

import {
  CheckIcon, ArrowLeftCircleIcon, CheckCircleIcon, DocumentIcon, BuildingOfficeIcon, PlusIcon, TableCellsIcon, ListBulletIcon,
  PencilIcon,
  XMarkIcon,
  ArchiveBoxArrowDownIcon,
  ArchiveBoxXMarkIcon
} from "@heroicons/react/24/outline";
import moment from "moment";
import { hasMultipleCompanies, isAdmin } from "../utils/User";
import Cookies from "js-cookie";
import { Image as Img, AutoComplete, Segmented, Table, Upload, message } from "antd";
import { Confirmation } from "../components/Confirmation";
import { deleteRequest, userRequest } from "../makeRequest";

const Employees = () => {
  const [addLoading, setAddLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openArchivedModal, setOpenArchivedModal] = useState(false);
  const [step, setStep] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [display, setDisplay] = useState(localStorage.getItem('display') || 'table');
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [employees, setEmployees] = useState(null);
  const [archivedEmployees, setArchivedEmployees] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [fileList, setFileList] = useState([]);
  const clear = useRef();

  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > 750) {
              height *= 750 / width;
              width = 750;
            }
          } else {
            if (height > 750) {
              width *= 750 / height;
              height = 750;
            }
          }
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            resolve(blob);
          }, 'image/jpeg', 0.5);
        };
        img.onerror = (error) => {
          reject(error);
        };
      };
    });
  };

  const handleFileChange = async (info, type) => {
    if (info?.file?.size / 1024 / 1024 > 2) {
      message.error('La taille du fichier doit être inférieure à 2 Mo');
    } else {
      let newFileList = [...fileList];
      newFileList = newFileList.filter((file) => file.type !== type);

      const compressedFiles = await Promise.all(
        info.fileList.map(async (file) => {
          if (file.type.includes('image')) {
            const compressedImage = await compressImage(file.originFileObj);
            return { ...file, originFileObj: compressedImage, typeofdoc: type };
          }
          return { ...file, typeofdoc: type };
        })
      );

      newFileList.push({ type, fileList: compressedFiles });
      console.log(newFileList)
      setFileList(newFileList);
    }
  };

  useEffect(() => {
    if (isAdmin() || hasMultipleCompanies()) {
      fetchCompanies();
    } else {
      fetchMyEmployees();
      setSelectedCompany({ id: null, value: null });
    }
  }, []);

  const fetchMyEmployees = async () => {
    setEmployeeInfo({});
    try {
      const response = await fetch(`http://127.0.0.1:8000/api/me/employees`, {
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`
        }
      });
      const data = await response.json();
      let activeEmployees = [];
      let archivedEmployees = [];
      data['employees'].forEach((employee) => {
        if (employee.status === 'ACTIVE') {
          activeEmployees.push(employee);
        } else {
          archivedEmployees.push(employee);
        }
      });

      console.log(archivedEmployees)
      setEmployees(activeEmployees);
      setArchivedEmployees(archivedEmployees);
      setSelectedCompany({ id: data['company'].id, value: data['company'].name });
    }
    catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    setAlertMessage(null);
    setStep(0);
  }, [openModal]);


  const fetchCompanies = async () => {
    setCompanies(null);
    try {
      const response = await fetch(`http://127.0.0.1:8000/api/companies_all`, {
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`
        }
      });
      const data = await response.json();

      let companyList = [];
      data.forEach((company) => {
        companyList.push({ id: company.id, value: company.name });
      })
      setCompanies(companyList);
    }
    catch (error) {
      console.error('Error:', error);
    }
  }

  const addEmployee = async () => {
    setAddLoading(true);
    const formData = new FormData();
    fileList.forEach((file) => {
      file.fileList.forEach((f) => {
        formData.append('documents[]', f.originFileObj);
        formData.append('documents_types[]', f.typeofdoc);
      });
    });
    formData.append('lastname', employeeInfo.lastname);
    formData.append('firstname', employeeInfo.firstname);
    formData.append('gender', employeeInfo.gender);
    formData.append('nationality', employeeInfo.nationality);
    formData.append('birthday', employeeInfo.birthday);
    formData.append('department_birth', employeeInfo.departmentBirth);
    formData.append('place_birth', employeeInfo.placeBirth);
    formData.append('social_security', employeeInfo.socialSecurity);
    formData.append('contract', employeeInfo.contract);
    formData.append('salary', employeeInfo.salary);
    formData.append('hiring_date', employeeInfo.hiringDate);
    formData.append('end_date', employeeInfo.endDate);
    formData.append('trial', employeeInfo.trial);
    formData.append('working_hours', employeeInfo.workingHours);
    formData.append('company', selectedCompany.id);

    try {
      const response = await fetch(`http://127.0.0.1:8000/api/employees`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
        body: formData,
      });
      const data = await response.json();
      if (data.id) {
        setOpenModal(false);
        setAddLoading(false);
        if (isAdmin() || hasMultipleCompanies()) {
          fetchEmployees(selectedCompany.id);
        } else {
          fetchMyEmployees()
        }
        clear.current.value = '';
        message.success('Employee ajouté avec succès');
      }
      // console.log('Success:', data);
    }
    catch (error) {
      console.error('Error:', error);
    }
  }

  const updateEmployee = async () => {
    setAddLoading(true);
    const formData = new FormData();
    fileList.forEach((file) => {
      file.fileList.forEach((f) => {
        formData.append('documents[]', f.originFileObj);
        formData.append('documents_types[]', f.typeofdoc);
      });
    });
    formData.append('lastname', employeeInfo.lastname);
    formData.append('firstname', employeeInfo.firstname);
    formData.append('gender', employeeInfo.gender);
    formData.append('nationality', employeeInfo.nationality);
    formData.append('birthday', employeeInfo.birthday);
    formData.append('department_birth', employeeInfo.departmentBirth);
    formData.append('place_birth', employeeInfo.placeBirth);
    formData.append('social_security', employeeInfo.socialSecurity);
    formData.append('contract', employeeInfo.contract);
    formData.append('salary', employeeInfo.salary);
    formData.append('hiring_date', employeeInfo.hiringDate);
    formData.append('end_date', employeeInfo.endDate);
    formData.append('trial', employeeInfo.trial);
    formData.append('working_hours', employeeInfo.workingHours);
    formData.append('company', selectedCompany.id);
    formData.append('id', employeeInfo.id);

    try {
      const response = await fetch(`http://127.0.0.1:8000/api/employees`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
        body: formData,
      });
      const data = await response.json();
      if (data.id) {
        setOpenModal(false);
        setAddLoading(false);
        if (isAdmin() || hasMultipleCompanies()) {
          fetchEmployees(selectedCompany.id);
        } else {
          fetchMyEmployees()
        }
        clear.current.value = '';
        message.success('Employee modifié avec succès');
      }
    }
    catch (error) {
      console.error('Error:', error);
    }


  }

  const fetchEmployees = async (companyId, search) => {
    setEmployeeInfo({});
    setEmployees(null);
    try {
      const response = await fetch(`http://127.0.0.1:8000/api/employees?company=${companyId}${search ? `&name=${search}` : ''}`);
      const data = await response.json();
      if (data.error) {
        setEmployees([]);
        message.error(data.error);
      } else {
        let activeEmployees = [];
        let archivedEmployees = [];
        data.forEach((employee) => {
          if (employee.status === 'ACTIVE') {
            activeEmployees.push(employee);
          } else {
            archivedEmployees.push(employee);
          }
        });
        console.log(archivedEmployees)
        setEmployees(activeEmployees);
        setArchivedEmployees(archivedEmployees);
      }
    }
    catch (error) {
      console.error('Error:', error);
    }
  }

  const deleteUser = (id) => async () => {
    Confirmation({
      title: 'Confirmation',
      confirmButtonText: 'Supprimer',
      content: <div>
        <p>Voulez-vous vraiment confirmer le départ de ce salarié ?</p>
        <hr className='my-2' />
        <small className='text-red-800'>Le salarié ne sera pas supprimé définitivement, il sera archivé, vous pouvez le restaurer à tout moment</small>
      </div>,
      onConfirm: async () => {
        try {
          const formData = new FormData();
          formData.append('id', id);
          formData.append('method', 'DELETE');
          const res = await userRequest.post("employees", formData, {
            headers: {
              'Content-Type': 'application/ld+json',
              'Accept': 'application/ld+json',
            }
          })
          if (res.status == 200) {
            message.success('Salarié archivé avec succès');

            if (isAdmin() || hasMultipleCompanies()) {
              fetchEmployees(selectedCompany.id)
            } else {
              fetchMyEmployees();
            }
          } else {
            message.error('Erreur lors de l\'archivage du salarié');
          }
        } catch (error) {
          message.error('Erreur lors de l\'archivage du salarié');
        }
      }
    })
  }

  const activateUser = (id) => async () => {
    Confirmation({
      title: 'Confirmation',
      confirmButtonText: 'Confirmer',
      content: <div>
        <p>Voulez-vous vraiment confirmer le retour de ce salarié ?</p>
        <hr className='my-2' />
        <small className='text-red-800'>Le salarié sera de nouveau actif</small>
      </div>,
      onConfirm: async () => {
        try {
          const formData = new FormData();
          formData.append('id', id);
          formData.append('method', 'ACTIVE');
          const res = await userRequest.post("employees", formData, {
            headers: {
              'Content-Type': 'application/ld+json',
              'Accept': 'application/ld+json',
            }
          })
          if (res.status == 200) {
            message.success('Salarié restauré avec succès');

            if (isAdmin() || hasMultipleCompanies()) {
              fetchEmployees(selectedCompany.id)
            } else {
              fetchMyEmployees();
            }
            setOpenArchivedModal(false);
          } else {
            message.error('Erreur lors de la restauration du salarié');
          }
        } catch (error) {
          message.error('Erreur lors de la restauration du salarié');
        }
      }
    })
  }

  useEffect(() => {
    if (employeeInfo?.documents) {
      let documentsByType = [];

      const fetchDocument = async (documentURL) => {
        try {
          const response = await fetch(documentURL);
          if (response.ok) {
            return await response.blob();
          } else {
            console.error("Failed to fetch document:", response.statusText);
            return null;
          }
        } catch (error) {
          console.error("Error fetching document:", error);
          return null;
        }
      };

      employeeInfo.documents.forEach(async (doc) => {
        const { fileType, fileNameHash, fileName } = doc;

        const documentURL = `http://127.0.0.1:8000/documents/${fileNameHash}`;

        const documentBlob = await fetchDocument(documentURL);

        if (documentBlob) {
          const typeIndex = documentsByType.findIndex((item) => item.type === fileType);

          if (documentBlob.type === 'application/pdf') {
            // Create a File object for PDF files
            const pdfFile = new File([documentBlob], fileNameHash + '.pdf', { type: documentBlob.type });

            if (typeIndex !== -1) {
              documentsByType[typeIndex].fileList.push({
                uid: fileNameHash,
                name: employeeInfo.lastname + '_' + employeeInfo.firstname + '_' + fileName,
                size: pdfFile.size,
                type: pdfFile.type,
                originFileObj: pdfFile,
                typeofdoc: fileType,
                thumbUrl: ""
              });
            } else {
              documentsByType.push({
                type: fileType,
                fileList: [{
                  uid: fileNameHash,
                  name: employeeInfo.lastname + '_' + employeeInfo.firstname + '_' + fileName,
                  size: pdfFile.size,
                  type: pdfFile.type,
                  originFileObj: pdfFile,
                  typeofdoc: fileType,
                  thumbUrl: ""
                }]
              });
            }
          } else {
            if (typeIndex !== -1) {
              documentsByType[typeIndex].fileList.push({
                uid: fileNameHash,
                name: employeeInfo.lastname + '_' + employeeInfo.firstname + '_' + fileName,
                size: documentBlob.size,
                type: documentBlob.type,
                originFileObj: documentBlob,
                typeofdoc: fileType,
                thumbUrl: documentURL
              });
            } else {
              documentsByType.push({
                type: fileType,
                fileList: [{
                  uid: fileNameHash,
                  name: employeeInfo.lastname + '_' + employeeInfo.firstname + '_' + fileName,
                  size: documentBlob.size,
                  type: documentBlob.type,
                  originFileObj: documentBlob,
                  typeofdoc: fileType,
                  thumbUrl: documentURL
                }]
              });
            }
          }
        }
      });

      console.log(documentsByType)
      setFileList(documentsByType);
    }
  }, [employeeInfo]);

  return (
    <div className="relative min-h-screen md:flex" data-dev-hint="container">
      <Navbar />
      <Sidebar />
      <main id="content" className="flex-1 p-6 lg:px-8">
        {selectedCompany ? (
          <>
            <span className="flex justify-between">
              {isAdmin() || hasMultipleCompanies() ? <div
                className="text-indigo-700 items-center flex "
                role="button"
                onClick={() => setSelectedCompany(null)}
              >
                <ArrowLeftCircleIcon className="h-7 w-7 inline-block me-2" />
                Retour
              </div> : null}
              <h1 className="text-2xl font-bold text-center items-center flex gap-1 ">
                <BuildingOfficeIcon className="h-8 w-5 inline-block" />
                <span>{selectedCompany.value}</span>
              </h1>
            </span>
            <div className="flex mt-5 items-baseline">
              {/* <div className="flex items-center me-5">
                <span className="text-sm me-2">Affichage</span>
                <Segmented
                  options={[
                    { label: <TableCellsIcon className="h-7 py-1 w-6" />, value: 'card' },
                    { label: <ListBulletIcon className="h-7 py-1 w-6" />, value: 'table' },
                  ]}
                  value={display}
                  onChange={(value) => {
                    setDisplay(value)
                    localStorage.setItem('display', value);
                  }}
                />
              </div> */}
              <div className="flex items-center">
                <span className="text-sm me-2">Rechercher</span>
                <input ref={clear} type="text" className="border border-gray-300 rounded-md px-2 py-1 placeholder-gray-500 placeholder-opacity-25" placeholder="Recherche par nom"
                  onChange={(e) => {
                    if (e.target.value === '') {
                      fetchEmployees(selectedCompany.id);
                    } else if (e.target.value.length > 3) {
                      fetchEmployees(selectedCompany.id, e.target.value);
                    }
                  }}
                />
              </div>
              <div className="ml-auto flex gap-2">
                {
                  archivedEmployees && archivedEmployees.length > 0 &&
                  <button
                    className="text-green-600 border rounded-xl inline-flex items-center justify-center py-2 px-2 text-center text-base font-medium"
                    onClick={() => {
                      setOpenArchivedModal(true);
                    }}
                  >
                    <ArchiveBoxArrowDownIcon className="h-6 w-6 me-1" />
                    Voir les salariés archivés
                  </button>

                }
                <button
                  className="bg-green-600 border-dark border rounded-xl inline-flex items-center justify-center py-2 px-5 text-center text-base font-medium text-white hover:bg-green-700 hover:border-body-color"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  <PlusIcon className="h-6 w-6" />
                  Ajouter un salarié
                </button>
              </div>
            </div>

            {
              employees ?
                employees?.length > 0 ? (
                  display === 'table' ? (
                    <Table
                      className=" mt-5"
                      dataSource={employees}
                      columns={[
                        {
                          title: 'Nom',
                          render: (record) => `${record.lastname} ${record.firstname}`,
                        },
                        {
                          title: 'Date d\'entrée',
                          dataIndex: 'createdAt',
                          render: (text) => moment(text).format('DD/MM/YYYY'),
                        },
                        {
                          title: 'Date de naissance',
                          dataIndex: 'birthday',
                          key: 'birthday',
                          render: (text) => moment(text).format('DD/MM/YYYY'),
                        },
                        // {
                        //   title: 'Nationalité',
                        //   dataIndex: 'nationality',
                        // },
                        {
                          title: 'Sexe',
                          dataIndex: 'gender',
                        },
                        {
                          title: 'Contrat',
                          dataIndex: 'contract',
                          key: 'contract',
                          render: (text) => <span className="bg-indigo-700 text-white rounded px-2 inline-block uppercase">{text}</span>,
                        },
                        {
                          title: 'Salaire',
                          dataIndex: 'salary',
                          key: 'salary',
                          render: (text) => `${text} €`,
                        },
                        {
                          title: 'Actions',
                          render: (record) => (
                            <div className="flex">
                              <button
                                className="flex font-sm border bg-gray-900 text-white px-2 py-1 rounded"
                                onClick={() => {
                                  setEmployeeInfo({
                                    ...record,
                                    documents: employees.find((employee) => employee.id === record.id).documents
                                  });

                                  // setTimeout(() => {
                                  setOpenInfoModal(true);
                                  // }, 100);
                                }}
                              >
                                <DocumentIcon className="h-5 w-5 me-1" />
                                <span className="text-sm">Voir détails</span>
                              </button>
                              <button
                                className="flex font-sm border bg-blue-600 text-white px-2 py-1 rounded ms-2"
                                onClick={() => {
                                  setOpenModal(true);
                                  setEmployeeInfo(record);
                                }}
                              >
                                <PencilIcon className="h-5 w-5 me-1" />
                                <span className="text-sm">Modifier</span>
                              </button>
                              <button
                                className="flex font-sm border bg-red-600 text-white px-2 py-1 rounded ms-2"
                                onClick={deleteUser(record.id)}
                              >
                                <ArchiveBoxArrowDownIcon className="h-5 w-5" />
                                {/* <span className="text-sm">Supprimer</span> */}
                              </button>
                            </div>
                          ),
                        },
                      ]}
                    />
                  ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-5">
                      {
                        employees?.map((employee) => (
                          <div className="bg-gray-100 shadow-sm rounded-md p-4">
                            <div className="flex justify-between mb-3">
                              <h5 className="font-bold whitespace-nowrap overflow-hidden overflow-ellipsis">
                                {employee.lastname} {employee.firstname}
                              </h5>
                              <span className="bg-indigo-700 text-white rounded px-2 inline-block uppercase">
                                {employee.contract}
                              </span>
                            </div>
                            <small className="block">Date de naissance : {moment(employee.birthday).format('DD/MM/YYYY')}</small>
                            <div className="flex align-bottom justify-between mt-4">
                              <button
                                className="flex font-sm border bg-gray-900 text-white px-2 py-1 rounded"
                                onClick={() => {
                                  setOpenInfoModal(true);
                                  setEmployeeInfo(employee);
                                }}
                              >
                                <DocumentIcon className="h-5 w-5 me-1" />
                                <span className="text-sm">Voir détails</span>
                              </button>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  )
                )
                  :
                  <div className="text-center rounded-md p-4 mt-10 mx-auto">
                    <svg height={100} className="mx-auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15.5 7.5C15.5 9.433 13.933 11 12 11C10.067 11 8.5 9.433 8.5 7.5C8.5 5.567 10.067 4 12 4C13.933 4 15.5 5.567 15.5 7.5Z" fill="#1C274C"></path> <path opacity="0.4" d="M19.5 7.5C19.5 8.88071 18.3807 10 17 10C15.6193 10 14.5 8.88071 14.5 7.5C14.5 6.11929 15.6193 5 17 5C18.3807 5 19.5 6.11929 19.5 7.5Z" fill="#1C274C"></path> <path opacity="0.4" d="M4.5 7.5C4.5 8.88071 5.61929 10 7 10C8.38071 10 9.5 8.88071 9.5 7.5C9.5 6.11929 8.38071 5 7 5C5.61929 5 4.5 6.11929 4.5 7.5Z" fill="#1C274C"></path> <path d="M18 16.5C18 18.433 15.3137 20 12 20C8.68629 20 6 18.433 6 16.5C6 14.567 8.68629 13 12 13C15.3137 13 18 14.567 18 16.5Z" fill="#1C274C"></path> <path opacity="0.4" d="M22 16.5C22 17.8807 20.2091 19 18 19C15.7909 19 14 17.8807 14 16.5C14 15.1193 15.7909 14 18 14C20.2091 14 22 15.1193 22 16.5Z" fill="#1C274C"></path> <path opacity="0.4" d="M2 16.5C2 17.8807 3.79086 19 6 19C8.20914 19 10 17.8807 10 16.5C10 15.1193 8.20914 14 6 14C3.79086 14 2 15.1193 2 16.5Z" fill="#1C274C"></path> </g></svg>
                    <h5 className="font-bold whitespace-nowrap overflow-hidden overflow-ellipsis">
                      Aucun employee trouvé
                    </h5>
                  </div>
                :
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-5">
                  {
                    [1, 2, 3, 4].map((index) => {
                      return <div className="animate-pulse bg-gray-100 shadow-sm rounded-md p-4">
                        <h5 className='py-3 bg-gray-300 rounded mb-2 w-[75%]'></h5>
                        <p className='py-2  bg-gray-300 rounded my-2'></p>
                        <small className='py-1 my-2 block bg-gray-300 w-1/2'></small>
                        <small className='py-1 my-2 block bg-gray-300 w-1/2'></small>
                        <div className='flex align-bottom justify-between mt-4'>
                          <div className='flex font-sm bg-gray-400 text-white px-2 py-1 w-[60px] rounded py-3 px-10'></div>
                          <div className='flex font-sm bg-gray-400 text-white px-2 py-1 w-[60px] rounded py-3 px-10'></div>
                        </div>
                      </div>
                    })
                  }
                </div>
            }
          </>
        ) : (
          <div className="flex justify-center items-center mt-20">
            <div className="bg-gray-50 shadow-xs p-6 rounded-lg text-center">
              <svg
                height={100}
                className="mx-auto mb-3"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    fillRule="evenodd"
                    d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                    fill="#1C274C"
                  ></path>
                </g>
              </svg>
              <p className="text-2xl">Les salariés</p>
              <small>
                Pour voir la liste des salariés, veuillez sélectionner une entreprise
              </small>
              <div className='text-center mt-3'>
                <AutoComplete
                  size='large'
                  className='text-left mx-auto'
                  options={companies}
                  style={{ width: 300 }}
                  onSelect={(company, option) => {
                    setSelectedCompany(option);
                    fetchEmployees(option.id);
                  }}
                  filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  placeholder="Rechercher une entreprise"
                />
              </div>
            </div>
          </div>
        )}
      </main>

      <Modal show={openInfoModal} size={"4xl"} onClose={() => setOpenInfoModal(false)}>
        <Modal.Header className='py-3'>
          <div className='flex justify-between gap-2 items-center'>
            <h5 className='font-bold whitespace-nowrap overflow-hidden overflow-ellipsis'>{employeeInfo?.firstname} {employeeInfo?.lastname}</h5>
            <p className='px-2 py-1 m-0 text-xs whitespace-nowrap overflow-hidden overflow-ellipsis bg-indigo-700 text-white rounded uppercase'>{employeeInfo?.contract}</p>

          </div>
        </Modal.Header>
        <Modal.Body className='pt-1'>
          <Tabs aria-label="Default tabs" style="default" theme={{
            tablist: {
              tabitem: {
                base: "flex items-center justify-center px-3 py-2 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:outline-none",
                styles: {
                  default: {
                    active: {
                      on: "bg-gray-100 text-indigo-700",
                    }
                  }
                }
              }
            }
          }}>
            <Tabs.Item active title="Les informations d'employee">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Nom de naissance : </span>
                <span>{employeeInfo?.lastname}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Prénoms : </span>
                <span>{employeeInfo?.firstname}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Nationalité : </span>
                <span>{employeeInfo?.nationality}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Sexe :  </span>
                <span>{employeeInfo?.gender}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Date de naissance :  </span>
                <span>{moment(employeeInfo?.birthday).format('DD/MM/YYYY')}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>N° de département de naissance :  </span>
                <span>{employeeInfo?.departmentBirth}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Commune de naissance :  </span>
                <span>{employeeInfo?.placeBirth}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>N° de sécurité sociale :  </span>
                <span>{employeeInfo?.socialSecurity}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Date d'entrée :  </span>
                <span>{moment(employeeInfo?.createdAt).format('DD/MM/YYYY')}</span>
              </div>

            </Tabs.Item>
            <Tabs.Item title="Contrat">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Type de contrat :  </span>
                <span>{employeeInfo?.contract}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Horaires hebdomadaires :  </span>
                <span>{employeeInfo?.workingHours}h</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Date prévisible d'embauche :  </span>
                <span>{moment(employeeInfo?.hiringDate).format('DD/MM/YYYY')}</span>
              </div>
              {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Heure prévisible d'embauche :  </span>
                <span>{employeeInfo?.hiringTime}</span>
              </div> */}
              {
                employeeInfo?.contract !== 'cdi' ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                    <span className='font-bold'>Date de fin de contrat :  </span>
                    <span>{moment(employeeInfo?.endDate).format('DD/MM/YYYY')}</span>
                  </div>
                )
                  : null
              }
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Rémunération brute mensuelle :  </span>
                <span>{employeeInfo?.salary} €</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Durée de période d'essai :  </span>
                <span>{employeeInfo?.trial} jours</span>
              </div>
            </Tabs.Item>
            {
              <Tabs.Item title="Documents" >
                <form className="space-y-4">
                  <div className="w-full flex space-x-4">
                    <div className="w-1/2 p-3 rounded-xl border-dashed border-2 relative">
                      <label className="text-sm">Pièce d'identité</label>
                      <div className="flex space-x-2 mt-3 ">
                        {
                          employeeInfo?.documents
                            ?.filter((document) => document.fileType === 'id')
                            ?.map((document) => {
                              return (
                                document.fileName.includes('.pdf') ?
                                  <a href={`http://127.0.0.1:8000/documents/${document.fileNameHash}`} target="_blank" className="text-blue-500 underline">
                                    <DocumentIcon className="h-20 h-20" />
                                  </a>
                                  :
                                  <Img height={80} width={80} className="h-5 w-5" src={`http://127.0.0.1:8000/documents/${document.fileNameHash}`} />
                              );
                            }
                            )
                        }
                      </div>
                    </div>
                    <div className="w-1/2 p-3 rounded-xl border-dashed border-2 relative">
                      <label className="text-sm">Carte vitale ou attestation</label>
                      <div className="flex space-x-2 mt-3 ">
                        {
                          employeeInfo?.documents
                            ?.filter((document) => document.fileType === 'secu')
                            ?.map((document) => {
                              return (
                                document.fileName.includes('.pdf') ?
                                  <a href={`http://127.0.0.1:8000/documents/${document.fileNameHash}`} target="_blank" className="text-blue-500 underline">
                                    <DocumentIcon className="h-20 h-20" />
                                  </a>
                                  :
                                  <Img height={80} width={80} className="h-5 w-5" src={`http://127.0.0.1:8000/documents/${document.fileNameHash}`} />
                              );
                            }
                            )
                        }
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex space-x-4">
                    <div className="w-1/2 p-3 rounded-xl border-dashed border-2 relative">
                      <label className="text-sm">Justificatif de domicile</label>
                      <div className="flex space-x-2 mt-3 ">
                        {
                          employeeInfo?.documents
                            ?.filter((document) => document.fileType === 'home')
                            ?.map((document) => {
                              return (
                                document.fileName.includes('.pdf') ?
                                  <a href={`http://127.0.0.1:8000/documents/${document.fileNameHash}`} target="_blank" className="text-blue-500 underline">
                                    <DocumentIcon className="h-20 h-20" />
                                  </a>
                                  :
                                  <Img height={80} width={80} className="h-5 w-5" src={`http://127.0.0.1:8000/documents/${document.fileNameHash}`} />
                              );
                            }
                            )
                        }
                      </div>
                    </div>
                    <div className="w-1/2 p-3 rounded-xl border-dashed border-2 relative">
                      <label className="text-sm">RIB</label>
                      <div className="flex space-x-2 mt-3 ">
                        {
                          employeeInfo?.documents
                            ?.filter((document) => document.fileType === 'rib')
                            ?.map((document) => {
                              return (
                                document.fileName.includes('.pdf') ?
                                  <a href={`http://127.0.0.1:8000/documents/${document.fileNameHash}`} target="_blank" className="text-blue-500 underline">
                                    <DocumentIcon className="h-20 h-20" />
                                  </a>
                                  :
                                  <Img height={80} width={80} className="h-5 w-5" src={`http://127.0.0.1:8000/documents/${document.fileNameHash}`} />
                              );
                            }
                            )
                        }
                      </div>
                    </div>
                  </div>
                </form>
              </Tabs.Item>
            }
          </Tabs>
        </Modal.Body>
      </Modal>

      <Modal show={openModal} size={"4xl"} onClose={() => {
        setOpenModal(false)
        setEmployeeInfo({});
        setFileList([]);
      }}>
        <Modal.Header>Ajouter un employee</Modal.Header>
        <Modal.Body>
          <ol className="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse bg-gray-100 py-2 px-3 rounded-xl">
            <li
              className={
                "flex items-center space-x-2.5 rtl:space-x-reverse " +
                (step >= 0 ? "text-indigo-700 " : "text-gray-500 ")
              }
            >
              <span
                className={
                  "flex text-xs items-center justify-center w-5 h-5 border rounded-full shrink-0 " +
                  (step >= 0 ? "border-indigo-700 " : "border-gray-500 ")
                }
              >
                {step >= 1 ? <CheckIcon className="h-4 w-4" /> : 1}
              </span>
              <span>
                <h3 className="font-medium text-sm leading-tight">
                  Les informations d'employee
                </h3>
              </span>
            </li>
            <li
              className={
                "flex items-center space-x-2.5 rtl:space-x-reverse " +
                (step >= 1 ? "text-indigo-700 " : "text-gray-500 ")
              }
            >
              <span
                className={
                  "flex text-xs items-center justify-center w-5 h-5 border rounded-full shrink-0 " +
                  (step >= 1 ? "border-indigo-700 " : "border-gray-500 ")
                }
              >
                {step >= 2 ? <CheckIcon className="h-4 w-4" /> : 2}
              </span>
              <span>
                <h3 className="font-medium text-sm leading-tight">Contrat</h3>
              </span>
            </li>
            <li
              className={
                "flex items-center space-x-2.5 rtl:space-x-reverse " +
                (step >= 2 ? "text-indigo-700 " : "text-gray-500 ")
              }
            >
              <span
                className={
                  "flex text-xs items-center justify-center w-5 h-5 border rounded-full shrink-0 " +
                  (step >= 2 ? "border-indigo-700 " : "border-gray-500 ")
                }
              >
                {step >= 3 ? <CheckIcon className="h-4 w-4" /> : 3}
              </span>
              <span>
                <h3 className="font-medium text-sm leading-tight">Documents</h3>
              </span>
            </li>
          </ol>

          <div className="space-y-6 p-6">
            {step === 0 ? (
              <div>
                {
                  alertMessage &&
                  <Alert color={alertMessage.type}>
                    {alertMessage.message}
                  </Alert>
                }
                <div className="mt-3">
                  <form className="space-y-4">
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Nom de naissance *</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={employeeInfo?.lastname}
                          onChange={(e) => {
                            setEmployeeInfo({
                              ...employeeInfo,
                              lastname: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block">Prénoms *</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={employeeInfo?.firstname}
                          onChange={(e) => {
                            setEmployeeInfo({
                              ...employeeInfo,
                              firstname: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Nationalité *</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={employeeInfo.nationality}
                          onChange={(e) => {
                            setEmployeeInfo({
                              ...employeeInfo,
                              nationality: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="w-1/2">
                        <span className="block">Sexe *</span>
                        <div className="mt-2">
                          <label className="inline-flex items-center mr-6">
                            <input
                              type="radio"
                              className="form-radio"
                              name="gender"
                              checked={employeeInfo.gender === 'Homme'}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setEmployeeInfo({
                                    ...employeeInfo,
                                    gender: e.target.value,
                                  })
                                }
                              }}
                              value="Homme"
                            />
                            <span className="ml-2">Homme</span>
                          </label>
                          <label className="inline-flex items-center">
                            <input
                              type="radio"
                              className="form-radio"
                              name="gender"
                              value="Femme"
                              checked={employeeInfo.gender === 'Femme'}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setEmployeeInfo({
                                    ...employeeInfo,
                                    gender: e.target.value,
                                  })
                                }
                              }}
                            />
                            <span className="ml-2">Femme</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Date de naissance *</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="date"
                          required
                          defaultValue={moment(employeeInfo.birthday).format('YYYY-MM-DD')}
                          onChange={(e) => {
                            setEmployeeInfo({
                              ...employeeInfo,
                              birthday: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block">
                          N° de département de naissance *
                        </label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="number"
                          required
                          value={employeeInfo.departmentBirth}
                          onChange={(e) => {
                            setEmployeeInfo({
                              ...employeeInfo,
                              departmentBirth: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Commune de naissance *</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={employeeInfo.placeBirth}
                          onChange={(e) => {
                            setEmployeeInfo({
                              ...employeeInfo,
                              placeBirth: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block">N° de sécurité sociale</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="number"
                          value={employeeInfo.socialSecurity}
                          onChange={(e) => {
                            setEmployeeInfo({
                              ...employeeInfo,
                              socialSecurity: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : step === 1 ? (
              <div>
                {
                  alertMessage &&
                  <Alert color={alertMessage.type}>
                    {alertMessage.message}
                  </Alert>
                }
                <form className="space-y-4">
                  <div className="w-full flex space-x-4">
                    <div className="w-1/2">
                      <div>
                        <span className="block">Type de contrat{employeeInfo?.contract}</span>
                        <select
                          className="mt-2 block w-full rounded-md border-gray-300 shadow-sm"
                          defaultValue={employeeInfo?.contract != 'cdi' &&
                            employeeInfo?.contract != 'ctt' &&
                            employeeInfo?.contract != 'cdd' &&
                            employeeInfo?.contract != 'apprentissage' &&
                            employeeInfo?.contract != 'professionnalisation' ? (employeeInfo?.contract ? "other" : "") : employeeInfo?.contract}
                          onChange={(e) => {
                            setEmployeeInfo({
                              ...employeeInfo,
                              contract: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>Veuillez choisir un type de contrat</option>
                          <option value="cdi">Contrat de durée indéterminée</option>
                          <option value="ctt">Contrat de travail temporaire</option>
                          <option value="cdd">Contrat de durée déterminée</option>
                          <option value="apprentissage">Contrat d'apprentissage</option>
                          <option value="professionnalisation">Contrat de professionnalisation</option>
                          <option value="other">Autre, précisez</option>
                        </select>
                        {
                          employeeInfo?.contract === "other" || (
                            employeeInfo?.contract &&
                            !['cdi', 'ctt', 'cdd', 'apprentissage', 'professionnalisation'].includes(employeeInfo.contract)
                          ) ? (
                            <input
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                              type="text"
                              placeholder="Précisez le type de contrat"
                              value={employeeInfo?.contract != 'other' ? employeeInfo?.contract : ''}
                              onChange={(e) => {
                                setEmployeeInfo({
                                  ...employeeInfo,
                                  contract: e.target.value,
                                });
                              }}
                            />
                          ) : null
                        }
                      </div>
                    </div>
                    <div className="w-1/2">
                      <div>
                        <span className="block">Horaires hebdomadaires</span>
                        <div className="mt-1 flex flex-col">
                          <input
                            type="number"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                            placeholder="Nombre d'heures"
                            value={employeeInfo?.workingHours}
                            onChange={(e) => {
                              setEmployeeInfo({
                                ...employeeInfo,
                                workingHours: parseInt(e.target.value),
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex space-x-2">
                    <div className="w-1/2">
                      <label className="block">
                        Date prévisible d'embauche
                      </label>
                      <input
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                        type="date"
                        defaultValue={moment(employeeInfo?.hiringDate).format('YYYY-MM-DD')}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            hiringDate: e.target.value,
                          });
                        }}
                      />
                    </div>
                    {
                      employeeInfo?.contract !== null && employeeInfo?.contract !== 'cdi' ? (
                        <div className="w-1/2">
                          <label className="block">Date de fin de contrat</label>
                          <input
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                            type="date"
                            defaultValue={moment(employeeInfo?.endDate).format('YYYY-MM-DD')}
                            onChange={(e) => {
                              setEmployeeInfo({
                                ...employeeInfo,
                                endDate: e.target.value,
                              });
                            }}
                          />
                        </div>
                      ) : null
                    }
                  </div>



                  <div className="w-full flex space-x-4">
                    <div className="w-1/2">
                      <label className="block">Rémunération brute mensuelle</label>
                      <input
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                        type="number"
                        value={employeeInfo?.salary}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            salary: parseFloat(e.target.value)
                          });
                        }}
                      />
                    </div>
                    <div className="w-1/2">
                      <label className="block">Durée de période d'essai (jours)</label>
                      <input
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                        type="number"
                        value={employeeInfo?.trial}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            trial: parseInt(e.target.value)
                          });
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
            ) : step === 2 ? (
              <div>
                <form className="space-y-4">
                  <div className="w-full flex space-x-4">
                    <div className="w-1/2 p-3 rounded-xl border-dashed border-2 relative">
                      <label className="text-sm">Pièce d'identité</label>
                      <Upload
                        listType='picture'
                        onPreview={(e) => {
                          window.open(URL.createObjectURL(e.originFileObj));
                        }}
                        accept=".pdf, .png, .jpg, .jpeg"
                        fileList={fileList.find(file => file.type === 'id') ? fileList.find(file => file.type === 'id').fileList : []}
                        onChange={info => handleFileChange(info, 'id')}
                        beforeUpload={() => false}
                        showUploadList={true}
                      >
                        <button onClick={(e) => { e.preventDefault() }} className="border border-green-500 text-xs text-green-600 px-2 py-1 rounded absolute top-3 right-2">Télécharger</button>
                      </Upload>
                    </div>
                    <div className="w-1/2 p-3 rounded-xl border-dashed border-2 relative">
                      <label className="text-sm">Carte vitale ou attestation</label>
                      <Upload
                        listType='picture'
                        onPreview={(e) => {
                          window.open(URL.createObjectURL(e.originFileObj));
                        }}
                        accept=".pdf, .png, .jpg, .jpeg"
                        fileList={fileList.find(file => file.type === 'secu') ? fileList.find(file => file.type === 'secu').fileList : []}
                        onChange={info => handleFileChange(info, 'secu')}
                        beforeUpload={() => false}
                        showUploadList={true}
                      >
                        <button onClick={(e) => { e.preventDefault() }} className="border border-green-500 text-xs text-green-600 px-2 py-1 rounded absolute top-3 right-2">Télécharger</button>
                      </Upload>
                    </div>
                  </div>
                  <div className="w-full flex space-x-4">
                    <div className="w-1/2 p-3 rounded-xl border-dashed border-2 relative">
                      <label className="text-sm">Justificatif de domicile</label>
                      <Upload
                        listType='picture'
                        onPreview={(e) => {
                          window.open(URL.createObjectURL(e.originFileObj));
                        }}
                        accept=".pdf, .png, .jpg, .jpeg"
                        fileList={fileList.find(file => file.type === 'home') ? fileList.find(file => file.type === 'home').fileList : []}
                        onChange={info => handleFileChange(info, 'home')}
                        beforeUpload={() => false}
                        showUploadList={true}
                      >
                        <button onClick={(e) => { e.preventDefault() }} className="border border-green-500 text-xs text-green-600 px-2 py-1 rounded absolute top-3 right-2">Télécharger</button>
                      </Upload>
                    </div>
                    <div className="w-1/2 p-3 rounded-xl border-dashed border-2 relative">
                      <label className="text-sm">RIB</label>
                      <Upload
                        listType='picture'
                        onPreview={(e) => {
                          window.open(URL.createObjectURL(e.originFileObj));
                        }}
                        accept=".pdf, .png, .jpg, .jpeg"
                        fileList={fileList.find(file => file.type === 'rib') ? fileList.find(file => file.type === 'rib').fileList : []}
                        onChange={info => handleFileChange(info, 'rib')}
                        beforeUpload={() => false}
                        showUploadList={true}
                      >
                        <button onClick={(e) => { e.preventDefault() }} className="border border-green-500 text-xs text-green-600 px-2 py-1 rounded absolute top-3 right-2">Télécharger</button>
                      </Upload>
                    </div>
                  </div>
                </form>
              </div>
            ) : null}
          </div>
        </Modal.Body >
        <Modal.Footer className="flex justify-between">
          {step > 0 ? (
            <Button
              color="white"
              className="text-indigo-700 border border-indigo-700"
              onClick={() => setStep(step - 1)}
            >
              Précédent
            </Button>
          ) : (
            <Button
              color="white"
              className="text-indigo-700 border border-indigo-700"
              onClick={() => setOpenModal(false)}
            >
              Fermer
            </Button>
          )}
          {step < 2 ? (
            <Button
              color="white"
              className="bg-indigo-700 text-white"
              onClick={() => {
                if (step == 0 && employeeInfo?.lastname && employeeInfo?.firstname && employeeInfo?.nationality && employeeInfo?.gender && employeeInfo?.birthday && employeeInfo?.departmentBirth && employeeInfo?.placeBirth && employeeInfo?.socialSecurity) {
                  setStep(step + 1)
                  setAlertMessage(null);
                } else if (step == 1 && employeeInfo?.contract && employeeInfo?.workingHours && employeeInfo?.hiringDate && employeeInfo?.salary && employeeInfo?.trial) {
                  setStep(step + 1)
                  setAlertMessage(null);
                } else {
                  setAlertMessage({
                    type: 'failure',
                    message: 'Veuillez remplir tous les informations demandées'
                  })
                }
              }}
            >
              Suivant
            </Button>
          ) : (
            <Button
              color="white"
              className="bg-green-500 text-white"
              onClick={() => {
                if (employeeInfo?.id) {
                  updateEmployee();
                } else {
                  addEmployee();
                }
              }}
            >
              {
                addLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-green-500"></div>
                    <span>Ajout en cours...</span>
                  </div>
                ) : (
                  <>
                    <CheckCircleIcon className="h-5 w-5 me-2" />
                    {
                      employeeInfo?.id ? 'Modifier' : 'Ajouter'
                    }
                  </>
                )
              }
            </Button>
          )}
        </Modal.Footer>
      </Modal >
      <Modal show={openArchivedModal} size={"5xl"} onClose={() => setOpenArchivedModal(false)}>
        <Modal.Header>Liste des salariés archivés</Modal.Header>
        <Modal.Body>
          {
            archivedEmployees && <Table
              dataSource={archivedEmployees}
              columns={[
                {
                  title: 'Nom',
                  render: (record) => `${record.lastname} ${record.firstname}`,
                },
                {
                  title: 'Date de naissance',
                  dataIndex: 'birthday',
                  key: 'birthday',
                  render: (text) => moment(text).format('DD/MM/YYYY'),
                },
                {
                  title: 'Nationalité',
                  dataIndex: 'nationality',
                },
                {
                  title: 'Sexe',
                  dataIndex: 'gender',
                },
                {
                  title: 'Contrat',
                  dataIndex: 'contract',
                  key: 'contract',
                  render: (text) => <span className="bg-indigo-700 text-white rounded px-2 inline-block uppercase">{text}</span>,
                },
                {
                  title: 'Salaire',
                  dataIndex: 'salary',
                  key: 'salary',
                  render: (text) => `${text} €`,
                },
                {
                  title: 'Actions',
                  render: (record) => (
                    <div className="flex">
                      {/* <button
                        className="flex font-sm border bg-gray-900 text-white px-2 py-1 rounded"
                        onClick={() => {
                          setEmployeeInfo({
                            ...record,
                            documents: employees.find((employee) => employee.id === record.id).documents
                          });

                          // setTimeout(() => {
                          setOpenInfoModal(true);
                          // }, 100);
                        }}
                      >
                        <DocumentIcon className="h-5 w-5 me-1" />
                        <span className="text-sm">Voir détails</span>
                      </button> */}
                      <button
                        className="flex font-sm border bg-red-600 text-white px-2 py-1 rounded ms-2"
                        onClick={activateUser(record.id)}
                      >
                        <ArchiveBoxXMarkIcon className="h-5 w-5" />
                        <span className="text-sm">Restaurer</span>
                      </button>
                    </div>
                  ),
                },
              ]}
            />
          }

        </Modal.Body>

      </Modal>

    </div >
  );
};

export default Employees;
