import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import Autocomplete from "../components/Autocomplete";
import { Table, Tag, message } from "antd";
import { userRequest } from "../makeRequest";
import { isAdmin } from "../utils/User";

const Comptes = () => {
  const [modal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState({loading: false, title: ""});
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    roles: "",
    company: null
  });
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [filtredUsers, setFiltredUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [deletedUser, setDeletedUser] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});

  const fetchCompanies = async () => {
    try {
      const res = await userRequest.get("companies_all");
      if(res.status === 200){
        const data = res?.data;
        let companyList = [];
        data?.forEach((company) => {
          companyList.push({ id: company.id, value: company.name });
        })
        setCompanies(companyList);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchUserList = async () => {
    setIsLoading({ loading: true, title: "getUsers" });
    try {
      const res = await userRequest.get("users_list");
      if(res.status === 200){
        setUsers(res?.data);
        setFiltredUsers(res?.data);
      }
    } catch (error) {
      console.log(error);
    } finally{
      setIsLoading({ loading: false, title: "" });
    }
  }

  const openDeleteModal = (id) => {
    setDeleteModal(true);
    setDeletedUser(id);
  }
  const closeDeleteModal = () => setDeleteModal(false);
  const closeModal = () => {
    setOpenModal(false);
    setIsEdit(false);
    setUserData({
      firstname: "",
      lastname: "",
      email: "",
      tel: "",
      roles: "",
      company: null
    });
  };
  const showModal = () => {
    setOpenModal(true);
  };

  const validateForm = () => {
    const errors = {};
    if (!userData.firstname) {
      errors.firstname = "Le nom est requis";
    }
    if (!userData.lastname) {
      errors.lastname = "Le prénom est requis";
    }
    if (!userData.password && !isEdit) {
      errors.password = "Le mot de passe est requis";
    }
    if (!userData.email) {
      errors.email = "L'email est requis";
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      errors.email = "L'email n'est pas valide";
    }
    if (!userData.roles) {
      errors.roles = "Le rôle est requis";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading({loading: true, title: "submit"});
    if(validateForm()){
      try {
        const res = isEdit ?
          await userRequest.patch(`users/${userData.id}`, userData, {
            headers: {
              'Content-Type': 'application/merge-patch+json',
              'Accept': 'application/ld+json',
            }
          })
        : await userRequest.post("users", userData, {
          headers: {
            'Content-Type': 'application/ld+json',
            'Accept': 'application/ld+json',
          }
        });
        if(res.status === 201 || res.status === 200){
          closeModal();
          message.success('Utilisateur enregistré avec succès');
          await fetchUserList();
        }
      } catch (error) {
        console.log(error);
      }
    }
    setIsLoading({loading: false, title: ""});
  }

  const handleDelete = async (e) => {
    e.preventDefault();
    setIsLoading({loading: true, title: "delete"});
    try {
      const res = await userRequest.delete(`users/${deletedUser}`, {
        headers: {
          'Content-Type': 'application/ld+json',
          'Accept': 'application/ld+json',
        }
      });
      if(res.status === 204){
        closeDeleteModal();
        message.success('Utilisateur supprimé avec succès');
        await fetchUserList();
      }
    } catch (error) {
      console.log(error)
    } finally{
      setIsLoading({loading: false, title: ""});
    }
  }

  useEffect(() => {
    fetchCompanies();
    fetchUserList();
  }, []);

  useEffect(() => {
    if (searchValue) {
      const filterUsers = users?.filter((user) =>
        user?.firstname?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
        user?.lastname?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
        user?.email?.toLowerCase()?.includes(searchValue.toLowerCase())
      );
      setFiltredUsers(filterUsers);
    } else {
      setFiltredUsers(users);
    }
  }, [searchValue, users]);

  return (
    <div className="relative min-h-screen md:flex">
      <Navbar />
      <Sidebar />
      <main id="content" className="flex-1 p-6 lg:px-8">
        <div className="items-start justify-between md:flex mt-5">
          <div className="max-w-lg">
          <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
              Gestion des Comptes
          </h3>
          <p className="text-gray-600 mt-2">
              Page simplifiée pour une gestion efficace et sécurisée des comptes.
          </p>
          </div>
          <Button onClick={showModal} gradientMonochrome="purple" className="mt-5 md:mt-0">Ajouter</Button>
        </div>
        <div className="mt-5 flex justify-end">
          <div className="relative">
            <svg className="w-6 h-6 text-gray-400 absolute left-3 inset-y-0 my-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
            </svg>
            <input
                type="text"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                placeholder="Rechercher..."
                className="w-full pl-12 pr-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 rounded-lg sm:max-w-xs"
            />
          </div>
        </div>
          <Table
            className="mt-8"
            dataSource={filtredUsers}
            columns={[
              {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
              },
              {
                title: 'Nom',
                dataIndex: 'firstname',
                key: 'firstname',
              },
              {
                title: 'Prénom(s)',
                dataIndex: 'lastname',
                key: 'lastname',
              },
              {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
              },
              {
                title: 'Société',
                dataIndex: 'company',
                key: 'company',
                render: (record) => record ? companies?.find(company => company?.id === record)?.value : "-"
              },
              {
                title: 'Roles',
                dataIndex: 'roles',
                key: 'roles',
                render: (record) => record?.includes("ROLE_ADMIN") ?
                  <Tag color="blue">Administrateur</Tag> :
                record?.includes("ROLE_MANAGER") ?
                  <Tag color="purple">Gestionnaire</Tag> :
                record?.includes("ROLE_VIEWER") ?
                  <Tag color="cyan">Spectateur</Tag> :
                  <Tag color="green">Utilisateur</Tag>
              },
              {
                title: 'Actions',
                key: 'actions',
                render: (record) => (
                  <div className="flex">
                    <button
                      onClick={() => {
                        setOpenModal(true);
                        setIsEdit(true);
                        setUserData({...record});
                      }}
                      className="py-2 px-3 font-medium text-indigo-600 hover:text-indigo-500 duration-150 hover:bg-gray-50 rounded-lg"
                    >
                      Modifier
                    </button>
                    <button
                      onClick={() => openDeleteModal(record?.id)}
                      className="py-2 leading-none px-3 font-medium text-red-600 hover:text-red-500 duration-150 hover:bg-gray-50 rounded-lg"
                    >
                      Supprimer
                    </button>
                  </div>
                ),
              },
            ]}
            loading={isLoading.loading && isLoading.title === "getUsers"}
          />
        {deleteModal && <Modal show={deleteModal} size="md" onClose={closeDeleteModal} popup>
          <Modal.Header />
          <Modal.Body>
            <div className="text-center">
              <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-[#ff4d4f]" />
              <h3 className="mb-5 text-lg font-normal text-gray-500">
                Êtes-vous sûr de vouloir supprimer cet utilisateur ?
              </h3>
              <div className="flex justify-center gap-6">
                <Button color="gray" onClick={() => closeModal()}>Non, annuler</Button>
                <Button gradientMonochrome="failure" onClick={handleDelete} disabled={isLoading.loading && isLoading.title === "delete"}>
                  {(isLoading.loading && isLoading.title === "delete") &&<Spinner color="failure" aria-label="delete spinner" size="sm" className="mr-2" />}
                  Oui
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>}

        {modal && <Modal show={modal} onClose={closeModal}>
          <Modal.Header>{isEdit ? "Modifier l'utilisateur" : "Ajouter l'utilisateur" }</Modal.Header>
          <Modal.Body>
            <div className="space-y-6">
              <form>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="nom"
                  >
                    Nom <span className="text-[#ff4d4f]">*</span>
                  </label>
                  <input
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    id="nom"
                    type="text"
                    placeholder="Nom"
                    value={userData.firstname}
                    onChange={(e) => setUserData((prev) => ({ ...prev, firstname: e.target.value }))}
                    required
                  />
                  {errors.firstname && <p className="text-[#ff4d4f] text-sm">{errors.firstname}</p>}
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="prenom"
                  >
                    Prénom(s) <span className="text-[#ff4d4f]">*</span>
                  </label>
                  <input
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    id="prenom"
                    type="text"
                    placeholder="Prénom(s)"
                    value={userData.lastname}
                    onChange={(e) => setUserData((prev) => ({ ...prev, lastname: e.target.value }))}
                    required
                  />
                  {errors.lastname && <p className="text-[#ff4d4f] text-sm">{errors.lastname}</p>}
                </div>
                {/* Password Field */}
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="password"
                  >
                    Password <span className="text-[#ff4d4f]">*</span>
                  </label>
                  <input
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    id="password"
                    type="password"
                    placeholder="Password"
                    value={userData.password}
                    onChange={(e) => setUserData((prev) => ({ ...prev, password: e.target.value }))}
                    required
                  />
                  {errors.password && <p className="text-[#ff4d4f] text-sm">{errors.password}</p>}
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Email <span className="text-[#ff4d4f]">*</span>
                  </label>
                  <input
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={userData.email}
                    onChange={(e) => setUserData((prev) => ({ ...prev, email: e.target.value }))}
                    required
                  />
                  {errors.email && <p className="text-[#ff4d4f] text-sm">{errors.email}</p>}
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="tel"
                  >
                    Téléphone
                  </label>
                  <input
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    id="tel"
                    type="tel"
                    placeholder="Tél"
                    value={userData.phone}
                    onChange={(e) => setUserData((prev) => ({ ...prev, phone: e.target.value }))}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="roles"
                  >
                    Roles <span className="text-[#ff4d4f]">*</span>
                  </label>
                  <select
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    id="roles"
                    value={userData?.roles?.length > 0 ? userData?.roles[0] : ""}
                    onChange={(e) => setUserData((prev) => ({ ...prev, roles: [e.target.value] }))}
                    required
                  >
                    <option value="">Select Role</option>
                    {isAdmin() && <option value="ROLE_ADMIN">Admin</option>}
                    <option value="ROLE_Manager">Manager</option>
                    <option value="ROLE_VIEWER">Viewer</option>
                    <option value="ROLE_USER">User</option>
                  </select>
                  {errors?.roles && <p className="text-[#ff4d4f] text-sm">{errors?.roles}</p>}
                </div>
                {(!userData?.roles?.includes("ROLE_ADMIN") &&
                !userData?.roles?.includes("") &&
                isAdmin()) && <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="societe"
                  >
                    Société
                  </label>
                  <Autocomplete
                    placeholder="Rechercher une entreprise"
                    className="mt-3"
                    value={userData?.company}
                    options={companies}
                    optionLabel="value"
                    onItemSelect={(v) => {
                      setUserData((prev) => ({ ...prev, company: v.id }));
                    }}
                  />
                </div>}
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer className="flex justify-end">
            <Button onClick={closeModal} color="gray">Annuler</Button>
            <Button gradientMonochrome="purple" onClick={handleSubmit} disabled={isLoading.loading && isLoading.title === "submit"}>
              {(isLoading.loading && isLoading.title === "submit") &&<Spinner color="purple" aria-label="submit spinner" size="sm" className="mr-2" />}
              {userData?.id ? 'Modifier' : 'Créer'}
            </Button>
          </Modal.Footer>
        </Modal>}
      </main>
    </div>
  );
};

export default Comptes;
