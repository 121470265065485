import { UserIcon, HomeIcon, UsersIcon, CogIcon, ClockIcon, BuildingOfficeIcon, ArrowLeftStartOnRectangleIcon, CurrencyEuroIcon, ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import { isAdmin, isManager } from "../utils/User";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

function Sidebar() {
  const location = useLocation();

  return (
    <aside id="sidebar" className="bg-gray-50 max-h-screen fixed top-0 left-0 bottom-0 text-gray-900 md:w-64 w-3/4 space-y-6 pt-6 px-0 inset-y-0 transform md:relative md:translate-x-0 transition duration-200 ease-in-out md:flex md:flex-col md:justify-between overflow-y-auto z-50" data-dev-hint="sidebar; px-0 for frameless; px-2 for visually inset the navigation">
      <div className="flex flex-col space-y-6" data-dev-hint="optional div for having an extra footer navigation">
        <Link to="/" className="text-gray flex items-center space-x-2 px-4" title="CBS Social">
          {/* <span className="text-2xl font-extrabold whitespace-nowrap truncate">CBS Social</span> */}
          <img src={require('../assets/img/logo.png')} alt="CBS Social" className="w-20 h-20" />
          <span className="text-2xl font-bold ">Social</span>
        </Link>

        <nav data-dev-hint="main navigation" className="mt-10">
          <NavLinkWithActiveCheck to="/" currentLocation={location} icon={<HomeIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />} text="Dashboard" />
          <NavLinkWithActiveCheck to="/declarations" currentLocation={location} icon={<CurrencyEuroIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />} text="Les variables salariales" />
          {
            isAdmin() ?
              <NavLinkWithActiveCheck to="/companies" currentLocation={location} icon={<BuildingOfficeIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />} text="Liste des entreprises" />
              : null
          }
          <NavLinkWithActiveCheck to="/absences" currentLocation={location} icon={<ClipboardDocumentListIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />} text="Gestion des absences" />
          {
            isAdmin() ?
              <NavLinkWithActiveCheck to="/logs" currentLocation={location} icon={<ClockIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />} text="Historique des activités" />
              : null
          }
          <NavLinkWithActiveCheck to="/employees" currentLocation={location} icon={<UsersIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />} text="Liste des salariées" />
          {
            isAdmin() || isManager() ?
              <NavLinkWithActiveCheck to="/accounts" currentLocation={location} icon={<CogIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />} text="Gestion des comptes" />
              : null
          }
        </nav>
      </div>

      <nav data-dev-hint="second-main-navigation or footer navigation">
        <Link href="#" className="flex items-center space-x-2 py-3 px-4 mb-1 transition duration-200 hover:bg-gray-200 hover:rounded-2xl selected:bg-white">
          <UserIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />
          <span>
            {
              jwtDecode(Cookies.get('token')).fullname
            }
          </span>
        </Link>
        <div onClick={() => {
          Cookies.remove('token');
          window.location.href = '/';
        }} className="flex items-center space-x-2 py-3 px-4 mb-1 transition duration-200 hover:bg-gray-200 hover:rounded-2xl selected:bg-white cursor-pointer">
          <ArrowLeftStartOnRectangleIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />
          <span>Se déconnecter</span>
        </div>
      </nav>
    </aside>
  );
}

// Helper component to create NavLink with active check
function NavLinkWithActiveCheck({ to, currentLocation, icon, text }) {
  const isActive = currentLocation.pathname === to;
  const classNames = `flex items-center space-x-2 py-3 px-4 mb-1 transition duration-200 hover:bg-gray-200 selected:bg-white ${isActive ? 'border-l-[3px] border-indigo-700 hover:rounded-r-2xl bg-gray-200' : 'hover:rounded-2xl '}`;
  return (
    <a href={to} className={classNames}>
      {icon}
      <span>{text}</span>
    </a>
  );
}

export default Sidebar;
