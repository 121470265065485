import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";

export const isAdmin = () => {
  const token = Cookies.get('token');
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.roles.includes('ROLE_ADMIN');
    } catch (error) {
      return false;
    }
  }
  return false;
};

export const isManager = () => {
  const token = Cookies.get('token');
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.roles.includes('ROLE_MANAGER');
    } catch (error) {
      return false;
    }
  }
  return false;
}

export const hasMultipleCompanies = () => {
  const token = Cookies.get('token');
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.company == 'multiple'
    } catch (error) {
      return false;
    }
  }
  return false;
}

export const getCompany = () => {
  const token = Cookies.get('token');
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.company;
    } catch (error) {
      return null;
    }
  }
  return null;
}
