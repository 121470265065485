import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import {
  Tabs,
  Modal,
  Table,
  TextInput,
  Alert,
  Button,
  Spinner,
} from "flowbite-react";
import {
  CheckIcon,
  Bars3Icon,
  CheckCircleIcon,
  DocumentIcon,
  PlusIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import moment from 'moment';
import { isAdmin } from '../utils/User';
const Companies = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fetchingCompany, setFetchingCompany] = useState(false);
  const [creatingCompany, setCreatingCompany] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [filterOption, setFilterOption] = useState('name');
  const [selectedCompany, setSelectedCompany] = useState({});
  const [companies, setCompanies] = useState(null);
  const [step, setStep] = useState(0);
  const [alertMessage, setAlertMessage] = useState(null);
  const [administrators, setAdministrators] = useState([]);
  const [companyFound, setCompanyFound] = useState(false);
  const [companyData, setCompanyData] = useState({
    'name': '',
    'siret': '',
    'siren': '',
    'date_immatriculation': '',
    'start_date': '',
    'nature': '',
    'form': '',
    'activity': '',
    'code_naf': '',
    'capital': '',
    'address': '',
    'department': '',
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    getCompanies(currentPage);
  }, []);

  const getCompanies = async (page = 1, search = '') => {
    setCompanies(null);
    try {
      const response = await fetch(`http://127.0.0.1:8000/api/companies?page=${page}&${filterOption}=${search}`);
      const data = await response.json();
      setCompanies(data['hydra:member']);
      setTotalItems(data['hydra:totalItems']);
    }
    catch (error) {
      console.error('Error:', error);
    }
  }

  const handleSearch = () => {
    // setCurrentPage(1);
    getCompanies(1, searchQuery);
  }

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getCompanies(page);
  }


  const fetchCompany = async (search = '', page = 1) => {
    setFetchingCompany(true);
    setAlertMessage(null);
    try {
      const response = await fetch('http://127.0.0.1:8000/api/company/' + companyData.siret);
      const data = await response.json();
      if (data.name) {
        setCompanyData(data);
        setCompanyFound(true);

        const administratorsArray = Array.isArray(data?.administrators) ? data?.administrators : [data?.administrators];
        setAdministrators(prevAdministrators => [...prevAdministrators, ...administratorsArray.map(administrator => ({
          firstname: administrator.nom,
          lastname: administrator.prenom,
          email: ''
        }))]);

      }
    } catch (error) {
      console.error('Error:', error);
    }
    setFetchingCompany(false);
  }


  useEffect(() => {
    setCompanyData({});
    setCompanyFound(false);
    setAlertMessage(null);
  }, [showAddModal])

  useEffect(() => {
    console.log(isAdmin())
  }, [])

  const addCompany = async () => {
    setCreatingCompany(true);
    try {
      const response = await fetch(`http://127.0.0.1:8000/api/companies`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ company: companyData, administrators: administrators }),
      });
      const data = await response.json();
      if (data.error) {
        setAlertMessage({
          type: 'failure',
          message: data.error
        })
      } else {
        setStep(0);
        setCompanyData({});
        setCompanyFound(false);
        setAdministrators([]);
        setShowAddModal(false);
        getCompanies();
      }
      setCreatingCompany(false);
    }
    catch (error) {
      console.error('Error:', error);
    }
  }
  return (
    <div className="relative min-h-screen md:flex" data-dev-hint="container">
      <Navbar />
      <Sidebar />
      <main id="content" className="flex-1 p-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className='flex justify-between flex-wrap'>
            <div className="flex items-center">
              <select
                value={filterOption}
                onChange={(e) => setFilterOption(e.target.value)}
                placeholder="Rechercher par"
                className="ml-2 px-3 py-1 border-gray-300 rounded"
              >
                <option value="name">Nom</option>
                <option value="siret">SIRET</option>
              </select>
              <div className="relative">
                <input
                  className="block w-[300px] py-1 rounded-md border-gray-300 shadow-sm pr-8"
                  type='text'
                  placeholder='Recherche'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                {searchQuery && (
                  <button
                    onClick={() => {
                      setSearchQuery('');
                      getCompanies();
                    }}
                    className="absolute inset-y-0 right-0 top-2 right-1 flex items-center px-1 text-white rounded-full h-[18px] w-[18px] bg-red-500 focus:outline-none"
                  >
                    <XMarkIcon className="h-7 w-7" />
                  </button>
                )}
              </div>
              <button onClick={handleSearch} className="ml-2 px-3 py-1 bg-gray-200 rounded">Rechercher</button>
            </div>
            <button onClick={() => setShowAddModal(true)} className='bg-green-600 border-dark border rounded-xl inline-flex items-center justify-center py-2 px-7 text-center text-base font-medium text-white hover:bg-green-700 hover:border-body-color'>
              <PlusIcon className='h-6 w-6' />
              Ajouter une entreprise
            </button>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-10">
            {
              companies ?
                companies.map((company, index) => {
                  return (
                    <div key={index} className="bg-gray-100 shadow-sm rounded-md p-4">
                      <h5 className='font-bold whitespace-nowrap overflow-hidden overflow-ellipsis'>{company.name}</h5>
                      <p className='px-2 py-1 text-xs whitespace-nowrap overflow-hidden overflow-ellipsis bg-indigo-700 text-white rounded mb-2'>{company.legalForm}</p>
                      <small className='block'>SIREN : {company.siren}</small>
                      <small className='block'>SIRET : {company.siret}</small>
                      <div className='flex align-right justify-between mt-4'>
                        <button onClick={() => {
                          setSelectedCompany(company);
                          setShowModal(true);
                        }} className='flex font-sm bg-gray-800 text-white px-2 py-1 rounded'>
                          <Bars3Icon className='h-5 w-5' />
                          <span className='text-sm'>Détails</span>
                        </button>
                        {/* <button className='flex font-sm border border-gray-900 text-gray-900 px-2 py-1 rounded'>
                          <DocumentIcon className='h-5 w-5' />
                          <span className='text-sm'>Déclarations</span>
                        </button> */}
                      </div>
                    </div>
                  )
                })
                :
                [1, 2, 3, 4].map((index) => {
                  return <div className="animate-pulse bg-gray-100 shadow-sm rounded-md p-4">
                    <h5 className='py-3 bg-gray-300 rounded mb-2 w-[75%]'></h5>
                    <p className='py-2  bg-gray-300 rounded my-2'></p>
                    <small className='py-1 my-2 block bg-gray-300 w-1/2'></small>
                    <small className='py-1 my-2 block bg-gray-300 w-1/2'></small>
                    <div className='flex align-bottom justify-between mt-4'>
                      <div className='flex font-sm bg-gray-400 text-white px-2 py-1 rounded py-3 px-10'></div>
                      <div className='flex font-sm bg-gray-400 text-white px-2 py-1 rounded py-3 px-10'></div>
                    </div>
                  </div>
                })
            }
          </div>
          {(
            companies && companies.length > 0 &&
            <div className="flex justify-center mt-10">
              {[...Array(totalPages).keys()].map(page => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page + 1)}
                  className={`mr-2 px-3 py-1 rounded ${currentPage === page + 1 ? 'bg-gray-300' : 'bg-gray-200'}`}
                >
                  {page + 1}
                </button>
              ))}
            </div>
          )}
        </div>

      </main>

      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <Modal.Header className='py-3'>
          <div className='flex justify-between gap-2 items-center'>
            <h5 className='font-bold whitespace-nowrap overflow-hidden overflow-ellipsis'>{selectedCompany.name}</h5>
            <p className='px-2 py-1 m-0 text-xs whitespace-nowrap overflow-hidden overflow-ellipsis bg-indigo-700 text-white rounded'>{selectedCompany.legalForm}</p>

          </div>
        </Modal.Header>
        <Modal.Body className='pt-1'>
          <Tabs aria-label="Default tabs" style="default" theme={{
            tablist: {
              tabitem: {
                base: "flex items-center justify-center px-3 py-2 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:outline-none",
                styles: {
                  default: {
                    active: {
                      on: "bg-gray-100 text-indigo-700",
                    }
                  }
                }
              }
            }
          }}>
            <Tabs.Item active title="Identité de l'entreprise">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>SIRET : </span>
                <span>{selectedCompany?.siret}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>SIREN : </span>
                <span>{selectedCompany?.siren}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Date d'immatriculation : </span>
                <span>{moment(selectedCompany?.dateImmatriculation).format('DD/MM/YYYY')}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Début d’activité : </span>
                <span>{moment(selectedCompany?.startDate).format('DD/MM/YYYY')}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Nature de l'entreprise : </span>
                <span>{selectedCompany?.nature}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Activité principale :  </span>
                <span>{selectedCompany?.activity}</span>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Code APE :  </span>
                <span>{selectedCompany?.codeApe}</span>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Capital social :  </span>
                <span>{selectedCompany?.capital} €</span>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Adresse du siège :  </span>
                <span>{selectedCompany?.address}</span>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Département du siège :  </span>
                <span>{selectedCompany.department}</span>
              </div>
            </Tabs.Item>
            <Tabs.Item title="Gestionnaires">

              <Table>
                <Table.Head>
                  <Table.HeadCell>Nom</Table.HeadCell>
                  <Table.HeadCell>Prénom(s)</Table.HeadCell>
                  <Table.HeadCell>Email</Table.HeadCell>
                  <Table.HeadCell>Tél</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {
                    selectedCompany?.administrators?.map((administrators, index) => {
                      return (
                        <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800 p-0">
                          <Table.Cell className='p-0'>
                            <span>{administrators.firstname}</span>
                          </Table.Cell>
                          <Table.Cell>
                            <span>{administrators.lastname}</span>
                          </Table.Cell>
                          <Table.Cell>
                            <span>{administrators.email}</span>
                          </Table.Cell>
                          <Table.Cell>
                            <span>{administrators.tel}</span>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })
                  }
                </Table.Body>
              </Table>

            </Tabs.Item>

          </Tabs>
        </Modal.Body>

        {/* <Modal.Footer className='py-2'>
          <button
            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Fermer
          </button>
        </Modal.Footer> */}
      </Modal>


      <Modal show={showAddModal} size={"4xl"} onClose={() => setShowAddModal(false)}>
        <Modal.Header>Ajouter une entreprise</Modal.Header>
        <Modal.Body>
          <ol className="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse bg-gray-100 py-2 px-3 rounded-xl">
            <li
              className={
                "flex items-center space-x-2.5 rtl:space-x-reverse " +
                (step >= 0 ? "text-indigo-700 " : "text-gray-500 ")
              }
            >
              <span
                className={
                  "flex text-xs items-center justify-center w-5 h-5 border rounded-full shrink-0 " +
                  (step >= 0 ? "border-indigo-700 " : "border-gray-500 ")
                }
              >
                {step >= 1 ? <CheckIcon className="h-4 w-4" /> : 1}
              </span>
              <span>
                <h3 className="font-medium text-sm leading-tight">
                  Informations d'entreprise
                </h3>
              </span>
            </li>
            <li
              className={
                "flex items-center space-x-2.5 rtl:space-x-reverse " +
                (step >= 1 ? "text-indigo-700 " : "text-gray-500 ")
              }
            >
              <span
                className={
                  "flex text-xs items-center justify-center w-5 h-5 border rounded-full shrink-0 " +
                  (step >= 1 ? "border-indigo-700 " : "border-gray-500 ")
                }
              >
                {step >= 2 ? <CheckIcon className="h-4 w-4" /> : 2}
              </span>
              <span>
                <h3 className="font-medium text-sm leading-tight">Gestionnaires du compte</h3>
              </span>
            </li>
          </ol>

          <div className="space-y-6 p-6">
            {step === 0 ?
              companyFound ?
                <div className="mt-3">
                  <form className="space-y-4">
                    {
                      alertMessage &&
                      <Alert color={alertMessage.type}>
                        {alertMessage.message}
                      </Alert>
                    }
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">SIRET</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.siret}
                          onChange={(e) => setCompanyData({ ...companyData, siret: e.target.value })}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block">SIREN</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.siren}
                          onChange={(e) => setCompanyData({ ...companyData, siren: e.target.value })}
                        />
                      </div>
                    </div>

                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Nom de l'entreprise</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.name}
                          onChange={(e) => setCompanyData({ ...companyData, name: e.target.value })}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block">Nature de l'entreprise</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.nature}
                          onChange={(e) => setCompanyData({ ...companyData, nature: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Forme juridique</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.form}
                          onChange={(e) => setCompanyData({ ...companyData, form: e.target.value })}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block">Capital social</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="number"
                          required
                          value={companyData.capital}
                          onChange={(e) => setCompanyData({ ...companyData, capital: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Activité principale</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.activity}
                          onChange={(e) => setCompanyData({ ...companyData, activity: e.target.value })}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block">Code APE/NAF</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.code_naf}
                          onChange={(e) => setCompanyData({ ...companyData, code_naf: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Date d'immatriculation</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="date"
                          required
                          value={companyData.date_immatriculation}
                          onChange={(e) => setCompanyData({ ...companyData, date_immatriculation: e.target.value })}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block">Début d'activité</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="date"
                          required
                          value={companyData.start_date}
                          onChange={(e) => setCompanyData({ ...companyData, start_date: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Adresse du siège</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.address}
                          onChange={(e) => setCompanyData({ ...companyData, address: e.target.value })}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block">Département du siège</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="number"
                          required
                          value={companyData.department}
                          onChange={(e) => setCompanyData({ ...companyData, department: e.target.value })}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                :
                <>
                  <div className='flex gap-2 w-1/2 mx-auto flex-col content-center text-center justify-center'>
                    <p>Chercher une entreprise par son SIRET</p>
                    <TextInput
                      placeholder='SIRET'
                      type="number"
                      value={companyData.siret}
                      onChange={(e) => setCompanyData({ ...companyData, siret: e.target.value })}
                    />
                    <Button
                      color="white"
                      disabled={companyData?.siret?.length != 14 ? true : false}
                      className="bg-indigo-700 text-white"
                      onClick={() => {
                        fetchCompany()
                      }}
                    >
                      {
                        fetchingCompany ?
                          <div className='flex gap-2 items-center'>
                            <Spinner size="sm" />
                            <span>Recherche en cours</span>
                          </div>
                          :
                          'Rechercher'
                      }
                    </Button>
                    <small className='text-indigo-700 mt-3' role='button' onClick={() => setCompanyFound(true)}>Ajouter une entreprise manuellement</small>
                  </div>
                </>
              : step === 1 ? (
                <div>
                  {
                    alertMessage &&
                    <Alert color={alertMessage.type}>
                      {alertMessage.message}
                    </Alert>
                  }
                  <div className='flex justify-between my-4 items-center'>
                    <small>
                      Pour créer un compte, inserer l'email pour recevoir les informations de connexion
                    </small>
                    <Button
                      color="white"
                      className="bg-white border border-indigo-700 text-indigo-700"
                      size={"xs"}
                      onClick={() => {
                        setAdministrators([...administrators, { firstname: '', lastname: '', email: '' }]);
                      }}
                    >
                      <PlusIcon className='h-5 w-5' />
                      Ajouter
                    </Button>
                  </div>
                  <Tabs.Item title="Dirigeants et associés">
                    <Table>
                      <Table.Head>
                        <Table.HeadCell>Nom</Table.HeadCell>
                        <Table.HeadCell>Prénom(s)</Table.HeadCell>
                        <Table.HeadCell>Email</Table.HeadCell>
                        <Table.HeadCell></Table.HeadCell>
                      </Table.Head>
                      <Table.Body className="divide-y">
                        {
                          administrators ?
                            administrators.map((admin, index) => {
                              return (
                                <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800 p-0">
                                  <Table.Cell className='p-0'>
                                    <TextInput
                                      placeholder='Nom'
                                      sizing={"sm"}
                                      type='text'
                                      value={admin.firstname}
                                      onChange={(e) => {
                                        const updatedAdmins = [...administrators];
                                        updatedAdmins[index].firstname = e.target.value;
                                        setAdministrators(updatedAdmins);
                                      }}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <TextInput
                                      placeholder='Prénom(s)'
                                      sizing={"sm"}
                                      type='text'
                                      value={admin.lastname}
                                      onChange={(e) => {
                                        const updatedAdmins = [...administrators];
                                        updatedAdmins[index].lastname = e.target.value;
                                        setAdministrators(updatedAdmins);
                                      }}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <TextInput
                                      placeholder='Email'
                                      type='email'
                                      sizing={"sm"}
                                      value={admin.email}
                                      onChange={(e) => {
                                        const updatedAdmins = [...administrators];
                                        updatedAdmins[index].email = e.target.value;
                                        setAdministrators(updatedAdmins);
                                      }}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Button
                                      color="white"
                                      className="bg-red-500 text-white"
                                      size={"xs"}
                                      onClick={() => {
                                        const updatedAdministrators = administrators.filter((_, i) => i !== index);
                                        setAdministrators(updatedAdministrators);
                                      }}
                                    >
                                      X
                                    </Button>
                                  </Table.Cell>
                                </Table.Row>
                              )
                            })

                            : null
                        }
                      </Table.Body>
                    </Table>
                  </Tabs.Item>
                </div>
              ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer className="flex justify-between">
          {step > 0 ? (
            <Button
              color="white"
              className="text-indigo-700 border border-indigo-700"
              onClick={() => setStep(step - 1)}
            >
              Précédent
            </Button>
          ) : (
            <Button
              color="white"
              className="text-indigo-700 border border-indigo-700"
              onClick={() => setShowAddModal(false)}
            >
              Fermer
            </Button>
          )}
          {step < 1 ? (
            <Button
              color="white"
              disabled={companyFound ? false : true}
              className="bg-indigo-700 text-white"
              onClick={() => {
                //check if all fields are filled
                if (companyData.siret && companyData.siren && companyData.name && companyData.nature && companyData.form && companyData.capital && companyData.activity && companyData.code_naf && companyData.date_immatriculation && companyData.start_date && companyData.address && companyData.department) {
                  setStep(step + 1);
                } else {
                  setAlertMessage({
                    type: 'failure',
                    message: 'Veuillez remplir tous les champs'
                  })
                }
              }}
            >
              Suivant
            </Button>
          ) : (
            <Button
              color="white"
              disabled={creatingCompany ? true : false}
              className="bg-green-500 text-white"
              onClick={() => {
                let valid = true;
                administrators.map((admin) => {
                  if (!admin.firstname || !admin.lastname || !admin.email) {
                    valid = false;
                  }
                });
                if (valid) {
                  addCompany();
                } else {
                  setAlertMessage({
                    type: 'failure',
                    message: 'Veuillez remplir tous les informations demandées'
                  })
                }
              }}
            >
              {
                creatingCompany ?
                  <div className='flex gap-2 items-center'>
                    <Spinner size="sm" />
                    <span>Création en cours</span>
                  </div>
                  :
                  <>
                    <CheckCircleIcon className="h-5 w-5 me-2" />
                    Ajouter
                  </>
              }
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div >
  );
}

export default Companies
