import React, { useEffect } from 'react'
import { Modal } from 'antd'

export const Confirmation = ({ title, content, onConfirm, onCancel, size = 'sm', confirmButtonText = 'Confirmer' }) => {

  let modal = Modal.confirm({
    footer: null,
    title: null,
    icon: null,
    centered: true,
    width: size === 'sm' ? 400 : size === 'md' ? 600 : size === 'lg' ? 800 : 1000,
    className: 'mx-auto',
    styles: {
      body: {
        justifyContent: 'center',
      }
    },
    content: <div>
      <h3 className='text-center text-lg font-semibold mb-3'>{title}</h3>
      <p className='text-center'>{content}</p>
      <div className="flex justify-center mt-4">
        <button className="border border-red-500 text-red-500 px-4 py-1 rounded-md mr-2" onClick={() => modal.destroy()}>Annuler</button>
        <button className="bg-green-500 text-white px-4 py-1 rounded-md" onClick={()=>{
          onConfirm()
          modal.destroy()
        }}>{confirmButtonText}</button>
      </div>
    </div>
  });
}