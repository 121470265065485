import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Timeline from 'react-calendar-timeline'
import '../assets/css/timeline.css'
import moment from 'moment'
import { Button, Modal, Spinner } from 'flowbite-react';
import Autocomplete from '../components/Autocomplete';
import { AutoComplete, ConfigProvider, DatePicker, message, Spin, Upload } from 'antd'
import { userRequest } from '../makeRequest'
import dayjs from 'dayjs'
import fr_FR from 'antd/locale/fr_FR';

import 'dayjs/locale/fr';
dayjs.locale('fr');

const Absences = () => {
    const [fileList, setFileList] = useState([]);
    const [modal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState({ loading: false, title: "" });
    const [employees, setEmployees] = useState([]);
    const [absences, setAbsences] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [absenceData, setAbsenceData] = useState({
        employee: "",
        type: "",
        startDate: "",
        endDate: "",
        hoursPerDay: 0,
        status: ""
    });

    const closeModal = () => {
        setOpenModal(false);
        setAbsenceData({
            employee: "",
            type: "",
            startDate: "",
            endDate: "",
            hoursPerDay: 0,
            status: ""
        });
    };
    const showModal = () => {
        setOpenModal(true);
    };

    const compressImage = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    let width = img.width;
                    let height = img.height;
                    if (width > height) {
                        if (width > 750) {
                            height *= 750 / width;
                            width = 750;
                        }
                    } else {
                        if (height > 750) {
                            width *= 750 / height;
                            height = 750;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob((blob) => {
                        resolve(blob);
                    }, 'image/jpeg', 0.5);
                };
                img.onerror = (error) => {
                    reject(error);
                };
            };
        });
    };

    const handleFileChange = async (info, type) => {
        if (info?.file?.size / 1024 / 1024 > 2) {
            message.error('La taille du fichier doit être inférieure à 2 Mo');
        } else {
            let newFileList = [...fileList];
            newFileList = newFileList.filter((file) => file.type !== type);

            const compressedFiles = await Promise.all(
                info.fileList.map(async (file) => {
                    if (file.type.includes('image')) {
                        const compressedImage = await compressImage(file.originFileObj);
                        return { ...file, originFileObj: compressedImage, typeofdoc: type };
                    }
                    return { ...file, typeofdoc: type };
                })
            );

            newFileList.push({ type, fileList: compressedFiles });
            console.log(newFileList)
            setFileList(newFileList);
        }
    };

    const fetchAbsences = async () => {
        setIsLoading({ loading: true, title: "fetchAbsences" });
        try {
            const res = await userRequest.get("me/absences");
            if (res.status === 200) {
                const data = res?.data;
                const updatedEmployees = data?.employees?.map(employee =>
                    ({ ...employee, title: `${employee.firstname} ${employee.lastname}`, value: `${employee.firstname} ${employee.lastname}` })
                );
                const updatedAbsences = data?.employees?.map(employee => {
                    return employee?.absences?.map(absence =>
                    ({
                        id: absence?.id,
                        group: Number(absence?.employee?.split("/")[3]),
                        title: absence?.type,
                        start_time: moment(absence?.startDate),
                        end_time: moment(absence?.endDate),
                        itemProps: {
                            style: {
                                background: absence?.type === "Congé" ? '#2196f3' : absence?.type === "Rtt" ? '#0e9f6e' : absence?.type === "Maladie" ? '#ff4638' : '#ff9800',
                                border: 'none',
                                borderRadius: 5,
                                fontSize: 10
                            }
                        }
                    })
                    )
                });
                setSelectedCompany({ id: data?.company?.id, value: data?.company?.name });
                setEmployees(updatedEmployees);
                setAbsences(updatedAbsences);
            }
        }
        catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading({ loading: false, title: "" });
        }
    };

    const handleAbsence = async (e) => {
        e.preventDefault();
        setIsLoading({ loading: true, title: "submit" });
        try {
            const res = await userRequest.post("absences", absenceData, {
                headers: {
                    'Content-Type': 'application/ld+json',
                    'Accept': 'application/ld+json',
                }
            });
            if (res.status === 201) {
                setOpenModal(false);
                message.success("Absence ajoutée avec succès");
                await fetchAbsences();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading({ loading: false, title: "" });
        }
    };

    useEffect(() => {
        fetchAbsences();
    }, []);

    return (
        <div className="relative min-h-screen md:flex" data-dev-hint="container">
            <Navbar />
            <Sidebar />
            <main id="content" className="flex-1 p-6 lg:px-8">
                <div className="items-start justify-between md:flex mt-5">
                    <div className="max-w-lg">
                        <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
                            Gestion des Absences
                        </h3>
                        <p className="text-gray-600 mt-2">
                            Page simplifiée pour une gestion efficace et sécurisée des absences.
                        </p>
                    </div>
                    <Button onClick={showModal} gradientMonochrome="purple" className="mt-5 md:mt-0">Ajouter</Button>
                </div>
                <div className="max-w-4xl mx-auto">
                    <div className="px-4 py-6 sm:px-0">
                        <Spin tip="Chargement..." spinning={isLoading.loading && isLoading.title === "fetchAbsences"}>
                            <Timeline
                                groups={employees}
                                items={absences?.length > 0 ? absences : []}
                                stackItems
                                className='!bg-white'
                                defaultTimeStart={moment().startOf('month')}
                                defaultTimeEnd={moment().endOf('month')}
                            />
                        </Spin>
                    </div>
                </div>
                <Modal show={modal} onClose={() => { setOpenModal(false); }} className='z-[999]'>
                    <Modal.Header>Ajouter un Absence</Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6">
                            <form>
                                <div className="mb-4">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="employe"
                                    >
                                        Le nom de l'employé
                                    </label>
                                    <AutoComplete
                                        size='large'
                                        className='text-left mx-auto w-full'
                                        options={employees}
                                        // style={{ width: 300 }}
                                        onSelect={(employee, option) => {
                                            setAbsenceData((prev) => ({ ...prev, employee: `/api/employees/${option?.id}` }))
                                        }}
                                        filterOption={(inputValue, option) =>
                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        placeholder="Rechercher un employé"
                                    />
                                    {/* <Autocomplete
                                        placeholder="Rechercher un employé"
                                        className="mt-3"
                                        options={employees}
                                        optionLabel="fullname"
                                        onItemSelect={(v) => {
                                            
                                        }}
                                    /> */}
                                </div>
                                <div className="mb-4">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="dateStart"
                                    >
                                        Date de début
                                    </label>
                                    <ConfigProvider locale={fr_FR}>
                                        <DatePicker
                                            id="dateStart"
                                            size='large'
                                            // value={dayjs(absenceData?.startDate)}
                                            className='w-full'
                                            onChange={(date, dateString) => setAbsenceData({ ...absenceData, startDate: dateString })}
                                        />
                                    </ConfigProvider>
                                </div>
                                <div className="mb-4">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="dateEnd"
                                    >
                                        Date de fin
                                    </label>
                                    <ConfigProvider locale={fr_FR}>
                                        <DatePicker
                                            id="dateEnd"
                                            size='large'
                                            // value={dayjs(absenceData?.endDate)}
                                            className='w-full'
                                            onChange={(date, dateString) => setAbsenceData({ ...absenceData, endDate: dateString })}
                                        />
                                    </ConfigProvider>
                                </div>
                                <div className="mb-4">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="roles"
                                    >
                                        Type
                                    </label>
                                    <select
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                        id="type"
                                        onChange={(e) => setAbsenceData({ ...absenceData, type: e.target.value })}
                                    >
                                        <option value="">Selectionner un type</option>
                                        <option value="Congé">Congé</option>
                                        <option value="Rtt">Rtt</option>
                                        <option value="Maladie">Maladie</option>
                                        <option value="Autre">Autre</option>
                                    </select>
                                </div>
                                <div className=" p-3 rounded-xl border-dashed border-2 relative">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="roles"
                                    >
                                        Documents (optionnel)
                                    </label>
                                    <Upload
                                        listType='picture'
                                        onPreview={(e) => {
                                            window.open(URL.createObjectURL(e.originFileObj));
                                        }}
                                        accept=".pdf, .png, .jpg, .jpeg"
                                        fileList={fileList.find(file => file.type === 'id') ? fileList.find(file => file.type === 'id').fileList : []}
                                        onChange={info => handleFileChange(info, 'id')}
                                        beforeUpload={() => false}
                                        showUploadList={true}
                                    >
                                        <button onClick={(e) => { e.preventDefault() }} className="border border-green-500 text-xs text-green-600 px-2 py-1 rounded absolute top-3 right-2">Télécharger</button>
                                    </Upload>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="flex justify-end">
                        <Button onClick={closeModal} color="gray">Annuler</Button>
                        <Button onClick={handleAbsence} gradientMonochrome="purple">
                            {(isLoading.loading && isLoading.title === "submit") && <Spinner color="purple" aria-label="submit spinner" size="sm" className="mr-2" />}
                            Ajouter
                        </Button>
                    </Modal.Footer>
                </Modal>
            </main>
        </div>
    )
}

export default Absences