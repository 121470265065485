import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";

export const isAuthenticated = () => {
  const token = Cookies.get('token');
  
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; 
      return decodedToken.exp > currentTime;
    } catch (error) {
      return false;
    }
  }
  
  return false;
};
