import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect } from 'react';

const Autocomplete = ({ options, optionLabel, onItemSelect, className, placeholder, value, style, allowClear }) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    if (value && value !== "") {
      const selectedOption = options?.find(option => option?.id === value);
      if (selectedOption) {
        setInputValue(selectedOption[optionLabel]);
      }
    }
  }, [value, optionLabel, options]);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    const filteredOptions = options?.filter(option =>
      option[optionLabel]?.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filteredOptions);
  };

  const handleSelectOption = (option) => {
    setInputValue(option[optionLabel]);
    setFilteredOptions([]);
    onItemSelect(option);
  };

  return (
    <div className={"relative " + className}>
      <input
        type="text"
        style={style}
        value={inputValue}
        onChange={handleChange}
        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
        placeholder={placeholder}
      />
      {
        allowClear && inputValue && (<button
          onClick={() => {
            setInputValue('');
            onItemSelect(null);
          }}
          className="ml-2 flex items-center bg-red-500 text-white p-1 rounded absolute right-2 top-[11px]"
        >
          <XMarkIcon className="h-3 w-3" />
        </button>)
      }

      {filteredOptions.length > 0 && (
        <ul className="text-left absolute z-10 w-full overflow-auto max-h-[200px] mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          {filteredOptions?.map((option, index) => (
            <li
              key={index}
              onClick={() => handleSelectOption(option)}
              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
            >
              {option[optionLabel]}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
